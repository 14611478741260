import React, { useEffect, useLayoutEffect, useState, } from 'react'
import '../css/header.css'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import config from '../config.json'


import translate_icon from '../images/assets/translate.svg'
import tel from '../images/assets/tel.svg'
import mail from '../images/assets/mail.svg'


function Header(props) {

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        selectLanguage();
    }

    useLayoutEffect(() => {
        selectLanguage();
    })

    const selectLanguage = () => {

        let langage_list_items = document.querySelectorAll('.header-langage-list-item');
        langage_list_items.forEach(item => {
            item.classList.remove('selected');

            if (item.id === i18n.language) {
                item.classList.add('selected');
            }
        });

        //changer html lang header
        let html = document.querySelector('html');
        html.lang = i18n.language;

    }

    const [selectorState, setSelectorState] = useState(false);

    const selectorToggle = () => {
        setSelectorState(!selectorState);
    };

    const [contactInfo, setContactInfo] = useState(false);
    const [imageSrc, setImageSrc] = useState();

    // fetch contact info
    useEffect(() => {

        const fetchData = async () => {
            try {
                const contactResponse = await axios.get(config['apiUrl'] + 'api/contact');
                setContactInfo(contactResponse.data[0]);
                setImageSrc(contactResponse.data[0]['logo_name']);

            } catch (error) {
                console.error('There was an error fetching the data!', error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {


        // enlever les classes show du lien
        const header_link = document.querySelector('.header-mobile-onglets');
        if (header_link) {
            header_link.classList.remove('show');
        }

        const handleClickOutside = (e) => {
            if (!e.target.closest('.header-langage') && !e.target.closest('.header-langage-list')) {
                setSelectorState(false);
            }
        };

        if (selectorState) {
            document.querySelector('.header-langage-list').classList.add('show');
            // setBigBackgroundOpened(true)
            window.addEventListener('click', handleClickOutside);
        } else {
            document.querySelector('.header-langage-list').classList.remove('show');
            window.removeEventListener('click', handleClickOutside);
            // setBigBackgroundOpened(false)
        }

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [selectorState]);

    useEffect(() => {
        selectLanguage();
    }, []);


    // selectedOnglet
    useEffect(() => {
        let onglets = document.querySelectorAll('.header-links-list a');

        onglets.forEach(onglet => {
            onglet.classList.remove('selected');
        });

        switch (props.page) {
            case 'home':
                break;
            case 'projects':
                onglets[0].classList.add('selected');
                break;
            case 'about':
                onglets[1].classList.add('selected');
                break;
            case '/contact':
                onglets[2].classList.add('selected');
                break;
            default:
                break;
        }
    }, [props.page]);


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [headerType, setHeaderType] = useState('desktop');

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth < 600) {
            setHeaderType('mobile');
        } else {
            setHeaderType('desktop');
        }
    }, [windowWidth]);

    const handleMobileDiscover = () => {

        const onglets = document.querySelector('.header-mobile-onglets');
        const button = document.querySelector('.header-menu-button');
        const background = document.querySelector('.header-mobile-onglets-background');

        // pages 
        const home = document.querySelector('.home');
        const projects = document.querySelector('.projects');
        const project_title = document.querySelector('.opened-categorie.title-position');

        if (!onglets || !button || !background) return;

        if (onglets.classList.contains('show')) {
            onglets.classList.remove('show');
            button.classList.remove('show');
            background.classList.remove('show');


            //filter blur css
            if (home) {
                home.style.filter = 'none';
            }
            if (projects) {
                projects.style.filter = 'none';
                if (project_title) {
                    project_title.style.filter = 'none';
                }
            }


        }
        else {
            onglets.classList.add('show');
            button.classList.add('show');
            background.classList.add('show');


            if (home) {
                home.style.filter = 'blur(7px)';
            }
            if (projects) {
                projects.style.filter = 'blur(7px)';
                if (project_title) {
                    project_title.style.filter = 'blur(7px)';
                }
            }
        }

        // dispatch event click on menu 
        const event = new Event('discoverMenu');
        window.dispatchEvent(event);


    }

    // ------------------------------------------------------------------ fermer le menu en cliquant ailleur marche pas-------------------------------------------
    // Function to detect clicks outside the menu
    // const detectClickOutsideMenu = (event) => {


    //     const onglets = document.querySelector('.header-mobile-onglets');
    //     const button = document.querySelector('.header-menu-button');
    //     const background = document.querySelector('.header-mobile-onglets-background');

    //     // If menu is open and click is outside the menu, close the menu
    //     if (!onglets || !button || !background) {
    //         return;
    //     }
    //     if (
    //         onglets.classList.contains('show') &&
    //         !onglets.contains(event.target) && // click is not inside the menu
    //         !button.contains(event.target) // click is not on the button
    //     ) {
    //         handleMobileDiscover();
    //     }
    // };

    // // Attach event listener for outside clicks
    // document.addEventListener('click', detectClickOutsideMenu);

    // // Prevent propagation to the document click event when clicking inside the menu
    // const onglets = document.querySelector('.header-mobile-onglets');
    // if (onglets) {
    //     onglets.addEventListener('click', (event) => {
    //         event.stopPropagation();
    //     });
    // }
     // ------------------------------------------------------------------------------------------------------------------------------


    const handleContact = () => {

        const contact_container = document.querySelector('.contact-container-list');
        const contact_button = document.querySelector('.contact');
        const background = document.querySelector('.header-background');

        if (contact_container.classList.contains('show')) {
            contact_container.classList.remove('show');
            contact_button.classList.remove('selected');
            // setBigBackgroundOpened(false)
          
        } else {
            contact_container.classList.add('show');
            contact_button.classList.add('selected');
            // setBigBackgroundOpened(true)
        }

        // si on clique en dehors du container on ferme
        const handleClickOutside = (e) => {
            if (!e.target.closest('.contact-container')) {
                contact_container.classList.remove('show');
                contact_button.classList.remove('selected');
                // setBigBackgroundOpened(false)
            }
            // si on clique sur header-langage
            if (e.target.closest('.header-langage')) {
                // setBigBackgroundOpened(true);
            }
        };

        window.addEventListener('click', handleClickOutside);
    }


    const callLouis = () => {
        window.location.href = 'tel:' + contactInfo['telephone'];
    }

    const mailLouis = () => {
        window.location.href = ('mailto:' + contactInfo['mail']);
    }


    const [os , setOs] = useState(navigator.userAgentData ? navigator.userAgentData.platform.toLowerCase(): navigator.platform.toLowerCase());



    return (
        (headerType === 'desktop') ? (
            <>
            <div className="header-container">
                <div className="header-logo">
                    <img id="logo" src={'/assets/logo/'+imageSrc} alt="Logo Louis Gaday photographe et videaste Motpellier Occitanie"   onClick={() => props.page !== 'home' ? window.location.href = '/' : null} />
                </div>

                <div className="header-links-list">
                    {/*`<!-- Effect from https://codepen.io/kazemi/pen/aGXbMm -->`*/}
                    <a href="/projects">{t('projets')}</a>
                    <a href="/about">{t('about')}</a>
                    {/*<a href="/contact" className="secondary" >Contact</a>*/}
                    <div className={`contact-container ${os}`} >
                        <button  className={`contact ${contactInfo ? 'show' : ''}`} onClick={() => handleContact()}>{t('contact')}</button>

                        <div className="contact-container-list">
                            <address onClick={() => callLouis()}>
                                <img id="tel" src={tel} alt="tel" />
                                <p>{contactInfo['telephone']}</p>
                            </address>
                            <address onClick={() => mailLouis()}>
                                <img src={mail} alt="mail" />
                                <p>{contactInfo['mail']}</p>
                            </address>
                        </div>

                    </div>



                    <div className='header-translate-container'>
                        <div className="header-langage">
                            <img src={translate_icon} alt="icon translate" onClick={() => selectorToggle()} />
                        </div>

                        <div className="header-langage-list">
                            <div className="header-langage-list-item" id="en" onClick={() => changeLanguage('en')} >{t('langage-en')}</div>
                            <div className="header-langage-list-item" id="fr" onClick={() => changeLanguage('fr')} >{t('langage-fr')}</div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="header-background">
            </div>

            </>

        )

            :

            (headerType === 'mobile') ? (
                <>
                    <div className="header-container">
                        <div className="header-logo">
                            <img id="logo" src={'/assets/logo/'+imageSrc} alt="Louis Gaday photographe et videaste Motpellier Occitanie"  onClick={() => props.page !== 'home' ? window.location.href = '/' : null} />
                        </div>

                        <div className="header-mobile-right">

                            <button className="header-menu-button" onClick={() => handleMobileDiscover()}>{t('decouvrir')}</button>


                            <div className='header-translate-container'>
                                <div className="header-langage">
                                    <img src={translate_icon} alt="icon translate" onClick={() => selectorToggle()} />
                                </div>

                                <div className="header-langage-list">
                                    <div className="header-langage-list-item" id="en" onClick={() => changeLanguage('en')} >{t('langage-en')}</div>
                                    <div className="header-langage-list-item" id="fr" onClick={() => changeLanguage('fr')} >{t('langage-fr')}</div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="header-mobile-onglets">
                        <div className="header-links-list">
                            {/*`<!-- Effect from https://codepen.io/kazemi/pen/aGXbMm -->`*/}
                            <a href="/projects">{t('projets')}</a>
                            <a href="/about">{t('about')}</a>
                            {/*<a href="/contact" className='secondary'>Contact</a>*/}
                            <div className='contact-container'>
                                <button className="contact" onClick={() => handleContact()}>{t('contact')}</button>

                                <div className="contact-container-list">
                                    <address onClick={() => callLouis()}>
                                        <img id="tel" src={tel} alt="tel" />
                                        <p>{contactInfo['telephone']}</p>
                                    </address>
                                    <address onClick={() => mailLouis()}>
                                        <img src={mail} alt="mail" />
                                        <p>{contactInfo['mail']}</p>
                                    </address>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="header-mobile-onglets-background">
                    </div>
                </>
            )

                : null
    );
}

export default Header
