
export default function cursorCustom(cursor) {

    if (!cursor) return;

    document.addEventListener('mousemove', e => {
        cursor.style.display = 'flex';
        document.querySelector('body').style.cursor = 'none';
        
        cursor.style.left = e.pageX + 'px';
        cursor.style.top = e.pageY + 'px';
    });
    document.addEventListener('mouseleave', () => {
        cursor.style.display = 'none';
        document.querySelector('body').style.cursor = 'default';
    });
    document.addEventListener('mouseenter', () => {
        cursor.style.display = 'flex';
        document.querySelector('body').style.cursor = 'none';
    });
}
