import React, { useEffect,useState } from 'react';

export const LoadingContext = React.createContext()

export function LoadingProvider({ children }) {
   

    const [NBItemsLoading, setNBItemsLoading] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    function startLoading() {
        setIsLoading(true)
    }

    function stopLoading() {
        setIsLoading(false)
        window.location.reload();
    }

    function addLoadingItem() {
        if (NBItemsLoading === null) {
            setNBItemsLoading(1)
        }else{
            setNBItemsLoading(NBItemsLoading + 1)
        }
    }

    function removeLoadingItem() {
        setNBItemsLoading(NBItemsLoading - 1)
        // window.location.reload();
    }

    function removeLoadingItemAndReload() {
        setNBItemsLoading(NBItemsLoading - 1)
        window.location.reload();
    }

    function resetLoading() {
        setNBItemsLoading(0)
    }

    useEffect(() => {
        if (NBItemsLoading === null) {
            return
        }else{
            if (NBItemsLoading > 0) {
                startLoading()
            } else {
                stopLoading()
            }
        }
    }, [NBItemsLoading]);

 



    const value = {isLoading,addLoadingItem, removeLoadingItem,removeLoadingItemAndReload, resetLoading, NBItemsLoading};

    return (
        <LoadingContext.Provider value={value}>
            {children}
        </LoadingContext.Provider>
    );
}