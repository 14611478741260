import React, {useContext, useState,useEffect} from 'react'
import { ProjectListContext } from '../Context/ProjectsListContext'
import axios from 'axios'
import ProjectsVideosList from './ProjectsVideosList';
import ProjectDocuList from './ProjectsDocuList';
import ProjectsPhotoList from './ProjectsPhotoList'
const config = require('../config.json');


function ProjectListContainer() {

  const { openedProjectCategorie,  projectListData } = useContext(ProjectListContext);

  // const structureList = ['videos', 'documentaires', 'photographies']

  let port = process.env.BACKEND_API_PORT || 5555;
  const [thisStructure, setThisStructure] = useState()
    

  useEffect(() => {

    if (openedProjectCategorie === 'close') return
    else {
      getStructure()
    }

    function getStructure() {
      axios.get(config['apiUrl']+ `api/categorie/${openedProjectCategorie}/structure` )
      .then(response => {
        setThisStructure(response.data[0]['display_structure'])
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error)
      })
    }


  }, [openedProjectCategorie, port])


  function sortProjectListByCategorie(categorie){
    let sortedList =[]
    projectListData.forEach(project => {
      if (parseInt(project.categorie) === parseInt(categorie)) {
        sortedList.push(project)
      }
    })
    return sortedList
  }



  return (
    (openedProjectCategorie === 'close') ? null : (

      thisStructure === "videos" ? (
        <ProjectsVideosList  projectList={sortProjectListByCategorie(openedProjectCategorie)} />
      ) : 
      thisStructure === "documentaires" ? (
        <ProjectDocuList />
      ) : (
        thisStructure === "photographies" ? (
          <ProjectsPhotoList projectList={sortProjectListByCategorie(openedProjectCategorie)} />
        ) : null
      )
    )
  )
}

export default ProjectListContainer