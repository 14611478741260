import React, { useState, useEffect, useContext } from 'react'
import '../../css/dashboardCategories.css'
import axios from 'axios'
import config from '../../config.json'
import parseBool from '../../utils/parseBool';
import { DashboardMODIFContext } from '../../Context/DashboardMODIFContext';

import { LoadingContext } from '../../Context/LoadingContext';

function DashboardCategories() {

    const [categories, setCategories] = useState([]);
    const { isLoading, addLoadingItem, removeLoadingItem, resetLoading, NBItemsLoading } = useContext(LoadingContext);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(config['apiUrl'] + 'api/categories/all');
                setCategories(response.data);
            } catch (error) {
                console.error('There was an error fetching the data!', error);
            }
        };

        fetchData();
    }, []);




    function saveChanges() {
        const dashboardCategories = document.querySelectorAll('.dashboard-category');

        // Crée un tableau de promesses pour toutes les requêtes axios
        const requests = [];

        addLoadingItem();

        dashboardCategories.forEach((category) => {
            const id = category.id;
            const position = category.querySelector('input[type="number"]').value || category.querySelector('input[type="number"]').placeholder;
            const visible = category.querySelector('select').value;
            const name_french = category.querySelector('#fr').value || category.querySelector('#fr').placeholder;
            const name_english = category.querySelector('#en').value || category.querySelector('#en').placeholder;
            const videoInput = category.querySelector('input[type="file"]');
            const display_structure = category.querySelector('#display_structure').value;
            const hasVideo = videoInput.files.length > 0;

            // Ajoute la promesse de mise à jour de la catégorie à la liste des requêtes
            const updateRequest = axios.post(config['apiUrl'] + 'api/categories/update', {
                id: id,
                position: position,
                visible: visible,
                name_french: name_french,
                name_english: name_english,
                display_structure: display_structure
            }, { withCredentials: true }).then(response => {
                if (hasVideo) {
                    // Si une vidéo est sélectionnée, ajoute la requête d'upload à la liste
                    const oldVideo = category.querySelector('#video_name').textContent;
                    const formData = new FormData();
                    formData.append('video', videoInput.files[0]);
                    formData.append('id', id);
                    formData.append('oldVideo', oldVideo);

                    return axios.post(config['apiUrl'] + 'api/categories/upload', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                }
            });

            requests.push(updateRequest); // Ajoute la requête à la liste
        });

        // Attends que toutes les requêtes soient terminées
        Promise.all(requests)
            .then(() => {
                // Toutes les requêtes sont terminées
                console.log('All updates and uploads completed.');

                dashboardCloseMODIF();
                window.location.reload(); // Recharge la page si nécessaire

            })
            .catch(error => {
                console.error('There was an error processing the requests!', error);
            });
    }


    function addCategory() {
        const nbexistant = categories.length;

        axios.post(config['apiUrl'] + 'api/categories/add', {
            nbexistant: nbexistant,
        }, { withCredentials: true })
            .then(response => {
                console.log('Category add response:', response);
                // Refresh the page
                window.location.reload();
            })
            .catch(error => {
                console.error('There was an error processing the request!', error);
            });
    }

    function deleteCategory(id) {
        if (window.confirm('Are you sure you want to delete this category?')) {
            console.log('Deleting category:', id);
            axios.post(config['apiUrl'] + 'api/categories/remove', {
                id: id,
            }, { withCredentials: true })
                .then(response => {
                    console.log('Category delete response:', response);
                    // Refresh the page
                    window.location.reload();
                })
                .catch(error => {
                    console.error('There was an error processing the request!', error);
                });
        }
    }

    function checkPositionInput(categoryId) {

        dashboardOpenMODIF()

        // Récupérer la valeur de l'input pour la catégorie actuelle
        const thisCategoryElement = document.getElementById(categoryId);
        const thisPositionValue = thisCategoryElement.querySelector('input[type="number"]').value;

        // Récupérer toutes les catégories
        const categories = document.querySelectorAll('.dashboard-category');

        const positions = [];

        categories.forEach(category => {
            const inputElement = category.querySelector('input[type="number"]');
            const position = inputElement.value !== '' ? inputElement.value : inputElement.placeholder;

            // Sauter l'élément actuellement modifié
            // if (parseInt(categoryId) === parseInt(category.id)) {
            //     return;
            // }

            positions.push(position);
        });


        positions.forEach((positionItem, index) => {
            // voir combien de fois la position actuelle est présente
            const positionCount = positions.filter(position => position === thisPositionValue).length;
            // console.log('Position count:', positionCount);

            if (positionCount > 1) {

                if (positionItem === thisPositionValue) {
                    // console.log('Position already exists', positionItem);

                    // on ne change pas la valeur de celui qu'on vient de changer
                    if (index === categoryId - 1) {
                        return
                    } else {
                        // input to change
                        const inputToChange = document.getElementById(index + 1).querySelector('input[type="number"]');

                        // on change par la valeur la plus petit qui n'est pas 0 qui n'est pas dans le tableau
                        for (let index = 1; index < positions.length + 1; index++) {
                            if (!positions.includes(index.toString())) {
                                console.log('Position already exists', positionItem, index);
                                inputToChange.value = index;
                            }
                        }
                    }
                }

            }


        });

        // bug valeur negative
        categories.forEach(category => {
            const inputElement = category.querySelector('input[type="number"]');
            const currentValue = inputElement.value ? inputElement.value : inputElement.placeholder;
            const currentValueType = inputElement.value ? 'value' : 'placeholder';
            //valeur absolute pour eviter les valeurs negatives
            inputElement.currentValueType = Math.abs(currentValue);
        });
    }

    //empecher le scroll dans les input text
    document.querySelectorAll('input[type=number]').forEach(input => {
        input.addEventListener('wheel', function (event) {
            event.preventDefault();
        });
    });


    const optionsVisible = [true, false];
    const optionsDisplayStructure = ['videos', 'documentaires', 'photographies'];

    const { dashboardOpenMODIF, dashboardCloseMODIF, dashboardMODIF } = useContext(DashboardMODIFContext);





    return (
        <div className='dashboard-categories-container'>
            <h2>Categories</h2>
            <div className='buttons'>
                <button className='add-category' onClick={addCategory}><img src={'/assets/inputs/plus.svg'} alt='plus' />Add Category</button>
                <button className='save-changes' onClick={saveChanges}><img src={'/assets/inputs/save.svg'} alt='plus' />Save Changes</button>
            </div>
            <div className='dashboard-categories-wrapper'>
                {categories.map((category, index) => {
                    return (
                        <div key={index} id={category.id} className='dashboard-category'>
                            <button className='delete-category' onClick={() => deleteCategory(category.id)}>Delete</button>
                            <h3>{'fr: '} <span>{category.name_french}</span> {', en: '} <span>{category.name_english}</span></h3>
                            <video className='dashboard-video' loop muted>
                                <source src={'/videos/categories/' + category.video_name} alt={category.name} />
                            </video>

                            <div className='infos'>
                                <div><p>position: </p><input type='number' placeholder={category.position} onChange={() => checkPositionInput(category.id)} /></div>
                                <div><p>visible: </p><select onChange={dashboardOpenMODIF} >
                                    {optionsVisible.map((option, index) => {
                                        let selected = false

                                        if ((category.visible === 1 && option === true) || (category.visible === 0 && option === false)) {
                                            selected = true
                                        }

                                        return (
                                            <option key={index} value={parseBool(option)} selected={selected}>{option.toString()}</option>
                                        )
                                    })}
                                </select></div>
                                <div><p>fr: </p><input id="fr" type='text' placeholder={category.name_french} onChange={dashboardOpenMODIF} /></div>
                                <div><p>en: </p><input id="en" type='text' placeholder={category.name_english} onChange={dashboardOpenMODIF} /></div>
                                <div><p>display_structure: </p><select id="display_structure" onChange={dashboardOpenMODIF}>
                                    {optionsDisplayStructure.map((option, index) => {
                                        let selected = false

                                        if (category.display_structure === option) {
                                            selected = true
                                        }

                                        return (
                                            <option key={index} value={option} selected={selected}>
                                                {option}
                                            </option>
                                        )
                                    })}
                                </select>
                                </div>
                                <div><p>video: <span id="video_name">{category.video_name}</span> : </p><input type='file' onChange={dashboardOpenMODIF} /></div>
                            </div>

                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export default DashboardCategories