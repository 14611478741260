export default function changeCanonialLink(pathName) {
    let canonicalTag = document.querySelector('link[rel="canonical"]');

    const url = 'https://louisgaday.fr' + pathName;
      
      if (!canonicalTag) {
        canonicalTag = document.createElement('link');
        canonicalTag.setAttribute('rel', 'canonical');
        document.head.appendChild(canonicalTag);
      }

      canonicalTag.setAttribute('href', url);

}
