import React, { useEffect, useState , forwardRef, useImperativeHandle} from 'react';
import '../../css/inputFile.css';
import inputFileFormatAcceptedFiles from '../../utils/inputFileFormatAcceptedFiles';
import axios from 'axios';
import {LoadingContext} from '../../Context/LoadingContext';
import config from '../../config.json';

// function InputFile(props) {
const InputFile = forwardRef((props, ref) => {
  const [preview, setPreview] = useState(null);
  const [previewFiles, setPreviewFiles] = useState([]);
  const fileInputRef = React.createRef();
  const fileInputLabelRef = React.createRef();
  const { addLoadingItem, removeLoadingItem } = React.useContext(LoadingContext);

  const handleFileChange = (e) => {
    handleFiles(e.target.files);
  };

  const handleFiles = (files) => {
    if (files.length > 0) {
      if (maxFiles === 1) {

        // notif si on a plus d'un fichier
        if (files.length > maxFiles) {
            console.error('You can only upload one file: NOTIFICATION');
        }

        const file = files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreview(reader.result);
          };
          reader.readAsDataURL(file);
          fileInputRef.current.files = files;
        }
      } else {
        const filesArray = Array.from(files).map(file => file.name);
        setPreviewFiles(filesArray);
        fileInputRef.current.files = files;
      }
    }
  };
  
    const accept = props.fileTypes ? inputFileFormatAcceptedFiles(props.fileTypes) : '.svg, .png, .jpg, .jpeg';

    const maxFiles = props.maxFiles ? parseInt(props.maxFiles) : 1;


    function uploadFile() {
        // Récupérer le fichier
        const files = fileInputRef.current.files;
        if (!files || files.length === 0) {
            console.error('No file chosen NOTIFICATION');
            return;
        }


        //ajout de la requete axios
        for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);
            addLoadingItem();

        
            axios.post(props.apiEndPointSave, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log('File uploaded successfully: NOTIFICATION', response.data); //  //reload page
                removeLoadingItem();


            }).catch(error => {
                console.error('Error while uploading file: NOTIFICATION');
                console.error(error);
            });
        }
    }

     // Rendre la fonction uploadFile accessible via un ref
  useImperativeHandle(ref, () => ({
    uploadFile
  }));


    // Fonction pour gérer le drag-over
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // Ajouter un style spécifique si le fichier est au-dessus de la zone de dépôt
        if (fileInputLabelRef.current) {
          fileInputLabelRef.current.classList.add('drag-over');
        }
      };
    
      const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // Réinitialiser le style lorsque le fichier quitte la zone de dépôt
        if (fileInputLabelRef.current) {
            fileInputLabelRef.current.classList.remove('drag-over');
        }
      };

  // Fonction pour gérer le drop
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    // handleFileChange(files);
    handleFiles(files);
    if (fileInputLabelRef.current) {
        fileInputLabelRef.current.classList.remove('drag-over');
      }
  };



  return (
    <div className='input-file-container' >
      <input 
        type='file' 
        id='file' 
        ref={fileInputRef}
        className='inputfile' 
        onChange={handleFileChange} // Ajout de l'événement onChange
        accept={accept} // Ajout de l'attribut accept
        multiple={maxFiles > 1 ? true : false} // Ajout de l'attribut multiple
      />
      <label htmlFor='file' ref={fileInputLabelRef}  onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
        <img src={'/assets/inputs/plus.svg'} alt='modify' />
      </label>
      <div className='input-preview'>
        {maxFiles === 1 ?
        
            (preview ? <img src={preview} alt='preview' /> : <p>No file chosen</p>)
    
        : 
        
            (previewFiles && previewFiles.length > 0 ? <p className='many-files'>{previewFiles.join(', ')}</p> : <p>No files chosen</p>)
        
        }



      </div>
    </div>
  );
});

export default InputFile;

