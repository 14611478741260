import React, { useEffect, useState, useContext } from 'react'
import Header from '../Components/Header'
import BgBigVideo from '../Components/BgBigVideo'
import '../css/projects.css'
import axios from 'axios';
import ProjectCategorie from '../Components/ProjectCategorie'
import ProjectListContainer from '../Components/ProjectListContainer'
import { CursorContext } from '../Context/CursorContext'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import  changeCanonialLink  from '../utils/changeCanonialLink';

const config = require('../config.json');

function Projects() {

  const { reloadCursorFunction } = useContext(CursorContext);
  // relaod cursor
  useEffect(() => {
    reloadCursorFunction()
  }, [])

  let port = process.env.BACKEND_API_PORT || 5555;

  const [marginTopProjectCategories, setMarginTopProjectCategories] = useState(0)
  const [categoryHeightOpened, setCategoryHeightOpened] = useState(0)
  const [marginTopHovered, setMarginTopHovered] = useState(0)
  const { showCursor } = useContext(CursorContext);

  function marginTopReset(marginTopProjectCategories) {
    const container = document.querySelector('.projects-categorie-container')
    container.style.marginTop = marginTopProjectCategories + "px"
  }


  useEffect(() => {
    function setSizesProjectsCategoriesPage() {
      // vérifier si les éléments sont disponibles
      const header = document.querySelector('.header-container');
      const projectCategorie = document.querySelector('.projects-categorie');

      if (header && projectCategorie) {
        // obtenir la taille de la fenêtre
        const windowHeight = window.innerHeight;

        // obtenir la taille de la barre de navigation
        const MARGIN_BOTTOM_HEADER = 25;
        const headerHeight = header.offsetHeight + MARGIN_BOTTOM_HEADER;

        // compter le nombre de .projects-categorie
        const projectCategorieElements = document.querySelectorAll('.projects-categorie');
        const projectCategorieCount = projectCategorieElements.length;

        const heightProjectCategory = projectCategorie.offsetHeight;

        const heightPageWithoutHeader = windowHeight - headerHeight;

        const heightProjectsCategories = heightProjectCategory * projectCategorieCount;

        // calculer la marge supérieure pour centrer les catégories de projets
        const newMarginTopProjectCategories =
          (heightPageWithoutHeader - heightProjectsCategories) / 2 + headerHeight;
        setMarginTopProjectCategories(newMarginTopProjectCategories);
        marginTopReset(newMarginTopProjectCategories);

        // height categorie
        let pixels_EN_MOINS_BAS = 2;
        setCategoryHeightOpened(
          heightPageWithoutHeader - (projectCategorieCount - 1) * heightProjectCategory - pixels_EN_MOINS_BAS
        );


        // data container
        const dataContainer = document.querySelector('.projects-categorie-DATA-container');
        const dataTopMargin = window.innerWidth < 800 ? 150 :180 ; // 150 tel , 180 desktop
        dataContainer.style.top = dataTopMargin + "px"
        dataContainer.style.height = windowHeight - dataTopMargin + "px"
        dataContainer.style.maxHeight = windowHeight - dataTopMargin + "px"


        // margin top hovered
        setMarginTopHovered(headerHeight);
      } else {
        // si les éléments ne sont pas encore disponibles, réessayer après un court délai
        setTimeout(setSizesProjectsCategoriesPage, 100);
      }
    }

    setSizesProjectsCategoriesPage();

    // if resize
    window.addEventListener('resize', setSizesProjectsCategoriesPage);

    // cleanup
    return () => {
      window.removeEventListener('resize', setSizesProjectsCategoriesPage);
    };
  }, []);


  const [categoriesData, setCategoriesData] = useState(null);

  useEffect(() => {

    showCursor()


    axios.get(config['apiUrl'] + 'api/categories')
      .then(response => {
        setCategoriesData(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, [port]);

  const { t } = useTranslation();
  // set title and meta description
  useEffect(() => {
    const title = t('projets') + ' - Louis Gaday';
    document.title = title;
  }, [t])


  const location = useLocation();
  useEffect(() => {
    changeCanonialLink(location.pathname);
  }, [location]);


  return (
    <>
      <Header page="projects" />

      <div className="projects">


        <div className="projects-categorie-container" onMouseLeave={() => marginTopReset(marginTopProjectCategories)}>

          {categoriesData && categoriesData.map((categorie, index) => {
            if (categorie.visible) {
              return <ProjectCategorie key={index} id={categorie.id} categorieName={categorie.name_french} categorieNameEN={categorie.name_english} videoName={categorie.video_name} heighOpened={categoryHeightOpened} marginTopHovered={marginTopHovered} />
            }
          }
          )}

        </div>

        <div className='projects-categorie-DATA-container'>
          <ProjectListContainer />
        </div>

      </div>
      { /*<BgBigVideo />*/}
    </>
  )
}

export default Projects


// // <ProjectCategorie categorieName="Clips de Musique" categorieNameEN="Music clips" videoName="Vald - Péon feat. Orelsan (Clip officiel) (1080p_25fps_H264-128kbit_AAC)COMPRESSED.mp4" heighOpened={categoryHeightOpened} marginTopHovered={marginTopHovered} />

// // <ProjectCategorie categorieName="Documentaires" categorieNameEN="Documentaries" videoName="Bigflo & Oli - Ça va beaucoup trop vite (Clip IA) (1080p_30fps_VP9-64kbit_Opus).mp4" heighOpened={categoryHeightOpened} marginTopHovered={marginTopHovered} />

// // <ProjectCategorie categorieName="Photographie" categorieNameEN="Photographs" videoName="orelsan-du-propre-clip-officiel2.mp4" heighOpened={categoryHeightOpened} marginTopHovered={marginTopHovered} />

