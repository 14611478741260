import React from 'react'
import '../../css/dashboard-loading.css'


function DashboardLoading() {
  return (
    <div className='container-dashboard-loading'>
        <div className='dashboard-loading'>
        </div>
        <h5>LOADING <span>.</span><span>.</span><span>.</span></h5>
    </div>
  )
}

export default DashboardLoading