import React, { useState, useEffect, useContext } from 'react'
import '../../css/dashboardCategoryContent.scss'
import axios from 'axios'
import config from '../../config.json'
import DashboardCategoryContentLine from './content/DashboardCategoryContentLine'
import {DashboardNavContext} from '../../Context/DashboardNavContext'

function DashboardCategoryContent(props) {

    const [categoryContent, setCategoryContent] = useState([])
    const [categoryStructure, setCategorySturcture] = useState([])
    const [categoryContentPhotos, setCategoryContentPhotos] = useState([])
    const [categoryContentDocus, setCategoryContentDocus] = useState([])

    const {dashboardNav} = useContext(DashboardNavContext)




    useEffect(() => {


        function sortProjectListByImportanceAndNew(liste) {
            const importanceHigh = []
            const importanceMedium = []
            const importanceWeak = []
            const importanceNone = []
            const newProjects = []

            liste.forEach(project => {
                if (project['isNew']) {
                    newProjects.push(project)
                } else {
                    if (project['IMPORTANCE'] === 'high') importanceHigh.push(project)
                    if (project['IMPORTANCE'] === 'medium') importanceMedium.push(project)
                    if (project['IMPORTANCE'] === 'weak') importanceWeak.push(project)
                    if (project['IMPORTANCE'] === 'none') importanceNone.push(project)
                }

            });

            const sortedList = newProjects.concat(importanceHigh,importanceMedium, importanceWeak, importanceNone)
            return sortedList
        }

        switch (categoryStructure) {
            case 'videos':
                axios.get(config['apiUrl'] + 'api/projects/videos')
                    .then((response) => {
                        setCategoryContent(sortProjectListByImportanceAndNew(response.data))
                    })
                    .catch((error) => {
                        console.error('There was an error!', error);
                    });
                break;
            
            case 'photographies':
                axios.get(config['apiUrl'] + '/api/dashboard/photos/all')
                    .then((response) => {
                        setCategoryContentPhotos(response.data)
                    })
                    .catch((error) => {
                        console.error('There was an error!', error);
                    });
                break;

                case 'documentaires':
                    axios.get(config['apiUrl'] + '/api/dashboard/documentaires/all')
                        .then((response) => {
                            setCategoryContentDocus(response.data)
                            console.log(response.data)
                        })
                        .catch((error) => {
                            console.error('There was an error!', error);
                        });
                    break;

            default:
                break;
        }

    }, [categoryStructure, dashboardNav])





    // changer le contenu en fonction de la categorie
    useEffect(() => {
        const setRightCategoryContent = () => {
            setCategorySturcture(props.category.display_structure)
        }

        if (props.category) {
            setRightCategoryContent()
        }
    }, [props])


    const addContentLine = () => {

        let urlAPI = ''

        switch (categoryStructure) {
            case 'videos':
                urlAPI = 'api/projects/videos/add'
                break;

            case 'photographies':
                urlAPI = 'api/addProjectPhoto/'
                break;

            case 'documentaires':
                    urlAPI = 'api/documentaire/add'
                    break;

            default:
                break;
        }
        axios.post(config['apiUrl'] + urlAPI, { withCredentials: true })
            .then((response) => {
                window.location.reload()
            })
            .catch((error) => {
                console.error('There was an error!', error);
            });

    }

    const addTag = () => {
        axios.post(config['apiUrl'] + '/api/addTag/', { withCredentials: true })
            .then((response) => {
                window.location.reload()
            })
            .catch((error) => {
                console.error('There was an error!', error);
            });
    }



    return (
        <div className='dashboard-category-container'>

        {categoryStructure === 'videos' ? <button className='dashboard-category-add-button add-category' onClick={addContentLine}><img src={'/assets/inputs/plus.svg'} alt='plus' />Ajouter</button> : null}

            {categoryStructure === 'videos' ?
                categoryContent.map((content, index) => {
                return (
                    <DashboardCategoryContentLine content={content} structure={categoryStructure} />
                )
            })
            :
            categoryStructure === 'photographies' ?
                <>
                    <h3>Projets photo</h3>
                        <button className='dashboard-category-add-button add-category' onClick={addContentLine}><img src={'/assets/inputs/plus.svg'} alt='plus' />Ajouter Projet</button>
                        {categoryContentPhotos.photosProjects && categoryContentPhotos.photosProjects.map(content => {
                            return(
                                <DashboardCategoryContentLine content={content} structure={categoryStructure} />
                            )
                        })}


                    <h3>Tags</h3>
                    <button className='dashboard-category-add-button add-category' onClick={addTag}><img src={'/assets/inputs/plus.svg'} alt='plus' />Ajouter Tag</button>
                    {categoryContentPhotos.tags && categoryContentPhotos.tags.map(content => {
                        return(
                            <DashboardCategoryContentLine content={content} structure={"photographie-tags"} />
                        )
                    })}
                </>
                : 
                categoryStructure === 'documentaires' ?
                    <>
                    <h3>Documentaires</h3>
                    <button className='dashboard-category-add-button add-category' onClick={addContentLine}><img src={'/assets/inputs/plus.svg'} alt='plus' />Ajouter Documentaire</button>
                    {categoryContentDocus && categoryContentDocus.map(content => {
                        return(
                            <DashboardCategoryContentLine content={content} structure={categoryStructure} />
                        )
                    })}
                    </>
                    :
                    null
                
        
        }
        </div>
    )
}

export default DashboardCategoryContent