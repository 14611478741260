import React, { useState, useEffect } from 'react';

export const DashboardNavContext = React.createContext()

export function DashboardNavProvider({ children }) {
   
    const [dashboardNav, setDashboardNav] = useState(localStorage.getItem('dashboardNav') ? parseInt(localStorage.getItem('dashboardNav')) : 0 );


    useEffect(() => {
        localStorage.setItem('dashboardNav', dashboardNav);
    }, [dashboardNav]);
   
   

    const value = {dashboardNav, setDashboardNav};

    return (
        <DashboardNavContext.Provider value={value}>
            {children}
        </DashboardNavContext.Provider>
    );
}