import React, {useEffect, useState, useContext} from 'react'
import Header from '../Components/Header'
import BgBigVideo from '../Components/BgBigVideo'
import axios from 'axios'
import '../css/home.css'
import { CursorContext } from '../Context/CursorContext'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import  changeCanonialLink  from '../utils/changeCanonialLink';

const config = require('../config.json');

function Home() {
  const {reloadCursorFunction} = useContext(CursorContext);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true)
  const [BGVideoOrdi, setBGVideoOrdi] = useState(null);
  const  [BGVideoTel, setBGVideoTel] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const {showCursor} = useContext(CursorContext);

  // set title and meta description
  useEffect(() => {
    const title = 'Louis Gaday - ' + t('photographe-videaste');
    document.title = title;

    document.querySelector('meta[name="description"]').setAttribute("content", "Photographe et vidéaste freelance basé dans le sud de la France, je suis passionné par l’art visuel et dédié à capturer des moments authentiques. Mon travail se concentre sur la création d’images qui racontent des histoires avec émotion et précision.");
  }, [t])


  // Get the videos
  useEffect(() => {

    axios.get(config['apiUrl']+'api/contact/videos')
      .then((response) => {
          setLoading(false)
          setBGVideoOrdi(response.data.video_ordinateur);
          setBGVideoTel(response.data.video_telephone);
      })
      .catch((error) => {
          console.log(error);
      });

  },[])

  // resize event
  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth);
    });
  }, [screenWidth]);

  // relaod cursor
  useEffect(() => {
    reloadCursorFunction()
  }, [])

  useEffect(() => {
    showCursor()
  }, [])

 

  const location = useLocation();
  useEffect(() => {
      changeCanonialLink(location.pathname);
  }, [location]);

  return (
    <>
        <Header page="home"/>
        <div className="home">
            {loading ? null : 
              screenWidth < 768 ? 
                <BgBigVideo videoName={BGVideoTel}  />
                :
                <BgBigVideo videoName={BGVideoOrdi}  />
            }
        </div>

        <div className="home-seo">
          <h1>Louis Gaday</h1>
          <h2>{t('photographe-videaste')}</h2>
        </div>

  
    </>
  )
}

export default Home
