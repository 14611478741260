
import React, { useEffect, useState, useRef, useContext } from 'react';
import '../../css/infosPerso.css';
import axios from 'axios';
import { LoadingContext } from '../../Context/LoadingContext';
import ReactPlayer from 'react-player';

//desactiver les erreur ligne suivante
import InputFile from './InputFile';
import ModifDeleteButtons from './ModifDeleteButtons';

import config from '../../config.json';


function InfosPerso(props) {
    const [infos, setInfos] = useState({});
    const [logoSrc, setLogoSrc] = useState('');
    const [mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [videoOrdinateur, setVideoOrdinateur] = useState('');
    const [videoMobile, setVideoMobile] = useState('');
    const [showReelLink, setShowReelLink] = useState('');
    const [showreeltitre, setShowreeltitre] = useState('');
    const [description_fr, setDescription_fr] = useState('');
    const [description_en, setDescription_en] = useState('');

    const { addLoadingItem, removeLoadingItem } = useContext(LoadingContext);

    useEffect(() => {
        if (props.contactInfo) {
            // Mettez à jour les états avec les informations de props
            setInfos(props.contactInfo);
            setLogoSrc(props.contactInfo.logo_name || 'default-logo.png'); // Valeur par défaut si logo_name est undefined
            setMail(props.contactInfo.mail || ''); // Valeur par défaut si mail est undefined
            setPhone(props.contactInfo.telephone || ''); // Valeur par défaut si phone est undefined
            setShowReelLink(props.contactInfo.showreel);
            setShowreeltitre(props.contactInfo.showreelTitle);
            setDescription_fr(props.contactInfo.text_description_about_fr);
            setDescription_en(props.contactInfo.text_description_about_en);
        }
    }, [props.contactInfo]); // Dépendance sur props.contactInfo

    useEffect(() => {
        axios.get(config['apiUrl'] + 'api/contact/videos')
            .then((response) => {
                setVideoOrdinateur(response.data.video_ordinateur);
                setVideoMobile(response.data.video_telephone);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    const [showInputFile, setShowInputFile] = useState(false);
    const [showInputFileBG, setShowInputFileBG] = useState(false);
    const [showInputFileBGtel, setShowInputFileBGtel] = useState(false);

    const inputFileRef = useRef();
    const handleSave = () => {
        if (inputFileRef.current) {
            inputFileRef.current.uploadFile();
        }
        setShowInputFile(false);

    };

    const [showInputText1, setShowInputText1] = useState(false);
    const [showInputText2, setShowInputText2] = useState(false);

    const handleSaveText = (id) => {
        const input = document.getElementById(`info-perso-${id}`).querySelector('input');
        const content = input.value;

        axios.post(config['apiUrl'] + 'api/contact/update', {
            id: id,
            content: content
        })
            .then((response) => {
                // console.log(response.data);
                //reload page
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
            });




    };

    const handleSaveBG = (device) => {
        let file
        let apiEndPoint
        if (device === 'ordi') {
            file = document.getElementById('input-ordi').files[0] ? document.getElementById('input-ordi').files[0] : null;
            apiEndPoint = 'ordinateur'
        } else {
            file = document.getElementById('input-tel').files[0] ? document.getElementById('input-tel').files[0] : null;
            apiEndPoint = 'telephone'
        }

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            addLoadingItem();

            axios.post(config['apiUrl'] + 'api/bgVideo/update/' + apiEndPoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                removeLoadingItem();
                window.location.reload();

            }).catch(error => {
                console.error('Error while uploading file: NOTIFICATION');
                console.error(error);
            });
        } else {
            window.location.reload();
        }

    }

    const [showInputTextShowreel, setShowInputTextShowreel] = useState(false);


    const handleSaveShowreel = () => {
        const inputLink = document.getElementById('input-showreel-link').value ? document.getElementById('input-showreel-link').value : document.getElementById('input-showreel-link').placeholder;
        const inputTitle = document.getElementById('input-showreel-title').value ? document.getElementById('input-showreel-title').value : document.getElementById('input-showreel-title').placeholder;

        axios.post(config['apiUrl'] + 'api/contact/update/showreel', {
            link: inputLink,
            title: inputTitle
        })
            .then((response) => {
                // console.log(response.data);
                //reload page
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const [showInputTextpropos, setShowInputTextPropos] = useState(false);


    const handleSaveAPropos = () => {
        const inputTextFR = document.getElementById('input-text-a-propos_fr').value;
        const inputTextEN = document.getElementById('input-text-a-propos_en').value;

        function ajoutRetourLigne(text) {
            return text.replace(/\n/g, '\r\n');
        }

        axios.post(config['apiUrl'] + 'api/contact/update/about', {
            text_fr: ajoutRetourLigne(inputTextFR),
            text_en: ajoutRetourLigne(inputTextEN)
        })
            .then((response) => {
                //reload page
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
            }
            );
        }


    return (
        <div className='infos-perso-container'>
            <h3>Informations personnelles</h3>

            <div className='info' id="info-perso-logo">
                <h4>Logo</h4>
                {logoSrc ? (
                    <img src={'assets/logo/' + logoSrc} alt='logo' />
                ) : (
                    <p>No logo available</p> // Message alternatif si logoSrc est vide
                )}

                {showInputFile && <InputFile ref={inputFileRef} apiEndPointSave={config['apiUrl'] + 'api/contact/update/logo'} maxFiles="1" fileTypes="svg, png" />}


                <ModifDeleteButtons onModify={() => setShowInputFile(true)} onSave={handleSave} />

            </div>

            <div className='info' id="info-perso-mail">
                <h4>Email</h4>
                {showInputText1 ? <input type="text" value={mail || 'No email provided'} onChange={(e) => setMail(e.target.value)} /> : <p>{mail || 'No email provided'}</p>}

                <ModifDeleteButtons onModify={() => setShowInputText1(true)} onSave={() => handleSaveText('mail')} />
            </div>

            <div className='info' id="info-perso-tel">
                <h4>Telephone</h4>
                {showInputText2 ? <input type="text" value={phone || 'No tel provided'} onChange={(e) => setPhone(e.target.value)} /> : <p>{phone || 'No tel provided'}</p>}

                <ModifDeleteButtons onModify={() => setShowInputText2(true)} onSave={() => handleSaveText('tel')} />
            </div>


            <div className='info' id="info-perso-videobg">
                <h4>Video background ordi</h4>
                {videoOrdinateur ? (
                    <video autoPlay muted loop preload="auto" playsInline webkit-playsinline="true">
                        <source src={'/videos/background_home/' + videoOrdinateur} type="video/mp4" />
                    </video>
                ) : (
                    <p>No logo available</p> // Message alternatif si logoSrc est vide
                )}

                {showInputFileBG && <input id="input-ordi" type="file" />}


                <ModifDeleteButtons onModify={() => setShowInputFileBG(true)} onSave={() => handleSaveBG('ordi')} />

            </div>

            <div className='info' id="info-perso-videobgtel">
                <h4>Video background tel</h4>
                {videoMobile ? (
                    <video autoPlay muted loop preload="auto" playsInline webkit-playsinline="true">
                        <source src={'/videos/background_home/' + videoMobile} type="video/mp4" />
                    </video>
                ) : (
                    <p>No logo available</p> // Message alternatif si logoSrc est vide
                )}

                {showInputFileBGtel && <input id="input-tel" type="file" />}


                <ModifDeleteButtons onModify={() => setShowInputFileBGtel(true)} onSave={() => handleSaveBG('tel')} />

            </div>

            <div className='info' id="info-perso-showreel">
                <h4>Showreel</h4>
                <div className="showreel-container">
                <ReactPlayer
                url={showReelLink}
                playing={false}
                controls={true}
                light={true}
                width="100%"
                height="100%"
                playsinline
            />
                </div>


                {showInputTextShowreel && <>lien: <input id="input-showreel-link" type="text" placeholder={showReelLink} /></>}
                
                {showInputTextShowreel && <>titre: <input id="input-showreel-title" type="text" placeholder={showreeltitre} /></>}


                <ModifDeleteButtons onModify={() => setShowInputTextShowreel(true)} onSave={() => handleSaveShowreel()} />

            </div>


            <div className='info' id="info-perso-showreel">
                <h4>A propos</h4>


                {showInputTextpropos && <>
                    texte FR: 
                    <textarea id="input-text-a-propos_fr" value={description_fr} onChange={(e) => setDescription_fr(e.target.value)} />
                    texte EN: 
                    <textarea id="input-text-a-propos_en" value={description_en} onChange={(e) => setDescription_en(e.target.value)} />
                </>}
                



                <ModifDeleteButtons onModify={() => setShowInputTextPropos(true)} onSave={() => handleSaveAPropos()} />

            </div>
        </div>
    );
}

export default InfosPerso;



