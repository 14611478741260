
import React, {useState, useEffect} from 'react'
import error_video from '../videos/error-video.mp4'
import '../css/error.scss'
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import  changeCanonialLink  from '../utils/changeCanonialLink';

const config = require('../config.json');


function Error() {

    const [logo, setLogo] = useState()

    useEffect(() => {
        axios.get(config['apiUrl']+'api/contact')
        .then((response) => {
            setLogo(response.data[0]['logo_name']);

        })
        .catch((error) => {
            console.log(error);
        });
    }, [])


     // set title and meta description
    useEffect(() => {
        const title = '404 Page not Found - Louis Gaday';
        document.title = title;
    }, [])


    const location = useLocation();
    useEffect(() => {
        changeCanonialLink(location.pathname);
    }, [location]);

    return (
        <>
        <div className='error-container-logo' onClick={() => window.location.href = '/'}>
            <img src={'/assets/logo/' + logo} alt='Logo Louis Gaday photographe et videaste Motpellier Occitanie'/>
        </div>

        <div className='error-container-video'>
            <video autoPlay muted loop preload="auto" playsInline webkit-playsinline="true">
                <source src={error_video} type="video/mp4" />
            </video>
        </div>

        <div className='error-container-text'>
            <h1>404</h1>
            <h2>Page not found</h2>

            <button onClick={() => window.location.href = '/'}>
            <img src={'/assets/icon.svg'} alt='back home' />
            Back to home
            </button>
        </div>

        </>
    )
}

export default Error