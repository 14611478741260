import React, { useState,useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/login.css';
import { CursorContext } from '../Context/CursorContext';


const config = require('../config');

const Login = ({ setAuthenticated }) => {
    const {reloadCursorFunction} = useContext(CursorContext);
    // relaod cursor
    useEffect(() => {
        reloadCursorFunction()
    }, [])

     // set title and meta description
     useEffect(() => {
        const title = 'Login page - Louis Gaday';
        document.title = title;
    }, [])

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const {hideCursor, showCursor} = useContext(CursorContext);

    const handleLogin = async () => {
        try {
            const response = await axios.post(config['apiUrl'] + 'login', { username, password }, { withCredentials: true });

            // Stocker le jeton JWT dans le sessionStorage
            sessionStorage.setItem('token', response.data.token);

            setAuthenticated(true);
            navigate('/dashboard');
        } catch (error) {
            alert('Invalid username or password');
        }
    };

    useEffect(() => {
        showCursor()
    }, [showCursor])


    return (
        <div className='login-container'>
            <h2>DASHBOARD LOUIS GADAY</h2>
            <h3>Login</h3>
            <div className='login-inputs'>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin}>Login</button>
            </div>
        </div>
    );
};

export default Login;
