import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';

import Home from './Pages/Home';
import Cursor from './Components/Cursor';
import Projects from './Pages/Projects';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import Error from './Pages/Error';
import MentionsLegales from './Pages/MentionsLegales';

import './css/userSelect.css'

import { ProjectListProvider } from './Context/ProjectsListContext';
import { LoadingProvider } from './Context/LoadingContext';
import { CursorProvider } from './Context/CursorContext';
import { DashboardNavProvider } from './Context/DashboardNavContext';
import { DashboardMODIFProvider } from './Context/DashboardMODIFContext';

import config from './config';
import About from './Pages/About';
import RGPD from './Pages/RGPD';
import Sitemap from './Pages/SiteMap';



function App() {

    useEffect(() => {
        const preventZoom = (event) => {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        };

        document.addEventListener('touchmove', preventZoom, { passive: false });

        return () => {
            document.removeEventListener('touchmove', preventZoom);
        };
    }, []);


    const [authenticated, setAuthenticated] = useState(null);

    useEffect(() => {
        const checkAuth = async () => {
            const token = sessionStorage.getItem('token'); // Récupérer le jeton du sessionStorage

            if (token) {
                try {
                    await axios.get(config['apiUrl'] + 'dashboard', {
                        headers: {
                            Authorization: `Bearer ${token}` // Ajouter le jeton à l'en-tête Authorization
                        }
                    });

                    setAuthenticated(true);
                } catch {
                    console.error('Invalid token');
                    setAuthenticated(false);
                }
            } else {
                setAuthenticated(false);
            }
        };

        checkAuth();
    }, []);



    const PrivateRouteDashboard = () => {
        if (authenticated === null) {
            // Afficher un indicateur de chargement ou rien du tout pendant le chargement de l'authentification
            return <div>Loading...</div>;
        }

        return authenticated ? <Dashboard /> : <Navigate to="/login" />;
    };




    return (
        <>
            <DashboardMODIFProvider>
                <DashboardNavProvider>
                    <CursorProvider>
                        <LoadingProvider>
                            <ProjectListProvider>
                                <Router>
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        <Route path="/home" element={<Home />} />
                                        <Route path="/projects" element={<Projects />} />
                                        <Route path="/about" element={<About />} />

                                        <Route path="/login" element={<Login setAuthenticated={setAuthenticated} />} />
                                        <Route path="/dashboard" element={<PrivateRouteDashboard />} />
                                        <Route path="*" element={<Error />} />

                                        <Route path="/legal-notices" element={<MentionsLegales />} />
                                        <Route path="/gdpr" element={<RGPD />} />
                                        <Route path="/rgpd" element={<RGPD />} />   
                                        <Route path="/site-map" element={<Sitemap />} />

                                    </Routes>
                                </Router>

                                <Cursor />


                            </ProjectListProvider>
                        </LoadingProvider>
                    </CursorProvider>
                </DashboardNavProvider>
            </DashboardMODIFProvider>
        </>
    );
}


export default App;
