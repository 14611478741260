import React, { useEffect, useRef, useState, useContext } from 'react'
import icon from '../images/assets/icon.svg'
import '../css/projects.css'
import { useTranslation } from 'react-i18next';
import { ProjectListContext } from '../Context/ProjectsListContext'

const config = require('../config.json');


function ProjectCategorie(props) {

    function marginTop(marginTopHovered) {
        document.documentElement.style.setProperty('--projects-categorie-margin-top', marginTopHovered + 'px');
        // console.log("margin top hovered",marginTop  )
        const container = document.querySelector('.projects-categorie-container')
        container.style.marginTop = marginTopHovered + "px"
    }

    useEffect(() => {
        // console.log("props",props.heighOpened)
        document.documentElement.style.setProperty('--projects-categorie-height-hovered', props.heighOpened + 'px');
    }, [props.heighOpened])


    const { i18n } = useTranslation();

    useEffect(() => {
        switch (i18n.language) {
            case 'en':
                setCategorieName(props.categorieNameEN)
                break;
            case 'fr':
                setCategorieName(props.categorieName)
                break;
            default:
                setCategorieName(props.categorieName)
        }
        changeOpenedTitleLangage()

    }, [i18n.language]);

    function changeOpenedTitleLangage() {
        const title = document.querySelector('.opened-categorie .opened-categorie-title-for-langage-changes');
        if (!title) return;


        if (i18n.language === 'en') {
            if (title.innerHTML === props.categorieName) {
                title.innerHTML = props.categorieNameEN;
            }
        } else {
            if (title.innerHTML === props.categorieNameEN) {
                title.innerHTML = props.categorieName;
            }
        }
    }


    const [touchdevice, setTouchdevice] = useState(false)
    useEffect(() => {
        //debug 
        // setTouchdevice(true)

        if ('ontouchstart' in window) {
            setTouchdevice(true)
        }
    }, [touchdevice])


    const [categorieName, setCategorieName] = useState(props.categorieName);

    let videosDirectory = "/videos/categories/"
    let videoURL = videosDirectory + props.videoName
    let videoType = "video/" + props.videoName.split('.').pop()

    let heighOpened = props.heighOpened
    // console.log(heighOpened)

    let marginTopHovered = props.marginTopHovered
    // console.log(marginTopHovered)


    const { closeCategorieProject, openCategorieProject } = useContext(ProjectListContext);


    const videoRef = useRef(null);


    const pauseVideo = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.style.filter = "grayscale(100%)"
        }
        //stoper les autres
        const videos = document.querySelectorAll('.projects-categorie-video')
        videos.forEach(video => {
            if (video !== videoRef.current) {
                video.pause()
                video.style.filter = "grayscale(100%)"
            }
        });
    };


    const playVideo = () => {
        if (videoRef.current) {
            // Vérifiez si la vidéo est prête à être lue (readyState 4 signifie qu'elle est entièrement prête)
            // if (videoRef.current.readyState >= 3) {
            //     videoRef.current.play().catch(error => {
            //         console.error("Erreur lors de la lecture de la vidéo:", error);
            //     });
            // } else {
            //     console.warn("La vidéo n'est pas encore prête à être lue.");
            // }
            // videoRef.current.play()
            videoRef.current.muted = true;
            videoRef.current.play().catch(error => {
                console.error("Erreur lors de la lecture de la vidéo:", error);
            });
        }

        // Stopper les autres vidéos
        const videos = document.querySelectorAll('.projects-categorie-video');
        videos.forEach(video => {
            if (video !== videoRef.current) {
                if (!video.paused) {
                    video.pause();
                    video.style.filter = "grayscale(100%)";
                }
            }
        });
    };


    function grayscaleVideo() {
        videoRef.current.style.filter = "grayscale(0%)"
    }


    function categorieHovered(marginTopHovered) {
        grayscaleVideo()
        playVideo()
        marginTop(marginTopHovered)
    }




    useEffect(() => {
        // reinitialiser local storage au chargement de la page
        localStorage.setItem('lastcategorieClicked', null);
    }, []);

    function categorieClickTouch(marginTopHovered, id) {

        // si pas de localStorage
        if (localStorage.getItem('lastcategorieClicked') === null || !localStorage.getItem('lastcategorieClicked')) {
            localStorage.setItem('lastcategorieClicked', id);
        } else {
            // sinon 
            if (parseInt(localStorage.getItem('lastcategorieClicked')) === id) {
                slideCategoriesOut();
                pauseVideo();
                localStorage.setItem('lastcategorieClicked', null);
                // on stoppe la fonction if pour ne pas executer le reste
                return
            } else {
                localStorage.setItem('lastcategorieClicked', id);
            }
        }

        // Les autres actions que vous souhaitez effectuer lors d'un clic
        grayscaleVideo();
        playVideo();
        marginTop(marginTopHovered);
    }

    // click menu
    useEffect(() => {
        const stopAllVideos = () => {
            pauseVideo()
        };

        window.addEventListener('discoverMenu', stopAllVideos);

        return () => {
            window.removeEventListener('discoverMenu', stopAllVideos);
        };
    }, []);


    const containerRef = useRef(null);
    const iconRef = useRef(null);
    const openedcategorieRef = useRef(null);

    function slideCategoriesOut() {

        const container = document.querySelector('.projects-categorie-container')
        // const container = containerRef.current;
        container.classList.add('slide-categories')

        // obtenir le text du h2 du containerRef actuel
        const opened_categorie_name = containerRef.current.getAttribute('id')
        openCategorieProject(opened_categorie_name)


        const data_container = document.querySelector('.projects-categorie-DATA-container')
        data_container.classList.add('opened')


        //obtenir position de ce projects-categorie-icon
        // let x_project = document.querySelector('.projects-categorie-icon').getBoundingClientRect().left
        // let y_project = document.querySelector('.projects-categorie-icon').getBoundingClientRect().top
        let y_project = iconRef.current.getBoundingClientRect().top

        const root = document.querySelector('#root')
        // ajouter une div de 50 par 50 bg rouge
        const div = document.createElement('div')
        div.classList.add('opened-categorie')
        const icon_element = document.createElement('div')
        icon_element.classList.add('projects-categorie-icon')
        const img = document.createElement('img')
        img.src = '/assets/icon.svg'
        img.alt = "back icon"
        icon_element.appendChild(img)
        div.appendChild(icon_element)
        const title = document.createElement('h2')
        title.ref = openedcategorieRef
        title.classList.add('opened-categorie-title-for-langage-changes')
        title.innerHTML = categorieName
        title.style.marginLeft = "0px"
        div.appendChild(title)

        div.style.top = y_project + "px"

        root.appendChild(div)

        // regle bug tel
        const opened_categorie_REMOVE = document.querySelectorAll('.opened-categorie')
        if (opened_categorie_REMOVE.length > 1) {
            // remove last opened categorie
            opened_categorie_REMOVE[0].remove()
        }


        // animation de la div en position titre + rotation de la fleche 
        const opened_categorie = document.querySelector('.opened-categorie')
        opened_categorie.classList.add('title-position')
        const animation_duration = 1000

        const icon_opened = document.querySelector('.opened-categorie .projects-categorie-icon')

        setTimeout(() => {
            opened_categorie.style.transition = "none"
            icon_opened.style.transition = "none"

            opened_categorie.style.top = "100px"

            // icon_opened.style.transform = "translate(-100px, 0px) rotate(-135deg) scale(0.6)  ;"
            icon_opened.classList.add('opened')

            //click event listener
            icon_opened.addEventListener('click', () => {
                slideCategoriesIn()
            })

        }, animation_duration - 1)

    }

    function slideCategoriesIn() {


        const container = document.querySelector('.projects-categorie-container')
        container.classList.remove('slide-categories')
        const opened_categorie = document.querySelector('.opened-categorie')
        opened_categorie.remove()
        const data_container = document.querySelector('.projects-categorie-DATA-container')
        data_container.classList.remove('opened')

        setTimeout(() => {
            closeCategorieProject()

            //supprimer openedcategorie
            const opened_categorie = document.querySelectorAll('.opened-categorie')
            if (opened_categorie) {
                opened_categorie.forEach(element => {
                    element.remove()
                })
            }
        }, 100)
    }


    return (
        (touchdevice ?
            (<div className="projects-categorie" id={props.id} ref={containerRef} onClick={() => categorieClickTouch(marginTopHovered, props.id)} >

                <div className="projects-categorie-icon" ref={iconRef} >
                    <img src={icon} alt="icon" />
                </div>
                <h2>{categorieName}</h2>

                <video className="projects-categorie-video" id={"video_" + props.categorieName} ref={videoRef} loop muted preload="auto" playsInline webkit-playsinline="true">
                    <source src={videoURL} type={videoType} />
                </video>
            </div>)

            :

            (
                <div className="projects-categorie" id={props.id} ref={containerRef} onClick={slideCategoriesOut} onMouseMove={() => categorieHovered(marginTopHovered)} onMouseLeave={pauseVideo} >

                    <div className="projects-categorie-icon" ref={iconRef} >
                        <img src={icon} alt="icon" />
                    </div>
                    <h2>{categorieName}</h2>

                    <video className="projects-categorie-video" id={"video_" + props.categorieName} ref={videoRef} loop muted preload="auto">
                        <source src={videoURL} type={videoType} />
                    </video>
                </div>
            ))
    )
}

export default ProjectCategorie
