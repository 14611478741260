import React, { useContext, useEffect, useState } from 'react'
import '../css/cursor.css'
import cursor_img from '../images/assets/pointeur.svg'
import cursorHoverClass from '../utils/cursorHoverClass'
import cursorClickClass from '../utils/cursorClickClass'
import disableDefaultCursor from '../utils/disableDefaultCursor'
import cursorCustom from '../utils/cursorCustom'
import cursorCategoriesHoverClass from '../utils/cursorCategoriesHoverClass'
import { CursorContext } from '../Context/CursorContext'
import {  motion, useMotionValue, useSpring } from 'framer-motion'


function Cursor() {

    const SMOOTCURSOR = false

    const {cursorVisible, reloadCursor,reloadCursorFunction} = useContext(CursorContext);

    useEffect(() => {

        const cursor = document.querySelector('.cursor-container');
        cursorCustom(cursor);


        //desactiver curseur sur les boutons et les liens et dans le body
        const divTodisable = ['a', '#logo', '.header-langage img', '.header-langage-list-item', 'body', 'button', '.error-container-logo'];
        disableDefaultCursor(divTodisable);

        // click
        cursorClickClass()


        const hoverableItems = ['.header-langage img', '#logo', '.header-langage-list-item', 'a', '.contact', '.contact-container-list', 'button', '.dashboard-header h2', '.dashboard-header img', '.error-container-logo'];
        cursorHoverClass(hoverableItems);

        const projectsCategories = document.querySelectorAll('.projects-categorie')
        cursorCategoriesHoverClass(projectsCategories);

    }, [reloadCursor]);

    const [touchdevice, setTouchdevice] = useState(false)

    useEffect(() => {
        //debug 
        // setTouchdevice(true)

        if ('ontouchstart' in window) {
            setTouchdevice(true)
        }

    }, [touchdevice, setTouchdevice])


    const mouse = {
        x: useMotionValue(0),
        y: useMotionValue(0)
    }

    const smoothOptions = { stiffness: 300, damping: 20, mass: 0.5 }
    const smoothMouse = {
        x: useSpring(mouse.x, smoothOptions),
        y: useSpring(mouse.y, smoothOptions)
    }

    function manageMouseMove(e) {
        const { clientX, clientY } = e
        mouse.x.set(clientX)
        mouse.y.set(clientY)
    }


    useEffect(() => {
        if(!SMOOTCURSOR) return

        window.addEventListener('mousemove', manageMouseMove)
        return () => {
            window.removeEventListener('mousemove', manageMouseMove)
        }
    }, [])


  return (
    (touchdevice === false ) ?

    cursorVisible &&

    <motion.div 
    className='cursor-container'
    style={
        {
            left: smoothMouse.x,
            top: smoothMouse.y,
            display: cursorVisible ? 'block' : 'none'
        }}
        >
        <img src={cursor_img} alt='cursor' className='cursor' />
    </motion.div>
    : <></>
  )
}

export default Cursor