import React, {useEffect} from 'react';
import '../css/mentions-legales.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import  changeCanonialLink  from '../utils/changeCanonialLink';

const MentionsLegales = () => {

    const { t, i18n } = useTranslation();

    // set title and meta description
    useEffect(() => {
        const title = t('Mentions légales')+' - Louis Gaday';
        document.title = title;
    }, [t])


    const location = useLocation();
    useEffect(() => {
        changeCanonialLink(location.pathname);
    }, [location]);

    return (
        i18n.language === 'fr' ?
            (<div className="mentions-legales">
                <button className="back-button" onClick={() => window.location.href = '/about'}>Retour</button>
                <h1>Mentions Légales</h1>

                <section>
                    <h2>Propriétaire du site</h2>
                    <p>
                        {"Nom de l'entreprise"}: <strong>Louis GADAY</strong><br />
                        Siège social: <strong>145 Chemin de la garrigue, 34470 Pérols, France</strong><br />
                        SIRET: <strong>92032790500013</strong><br />
                        Directeur de {"l'entreprise"}: <strong>Louis GADAY</strong>
                    </p>
                </section>

                <section>
                    <h2>Éditeur et développeur du site</h2>
                    <p>
                        {"Nom de l'entreprise"}: <strong>Rémy EROES</strong><br />
                        Siège social: <strong>168 rue Frédéric Mistral, 34280 La Grande-Motte, France</strong><br />
                        SIRET: <strong>92921139900019</strong><br />
                        Directeur de {"l'entreprise"}: <strong>Rémy EROES</strong>
                    </p>
                </section>

                <section>
                    <h2>Hébergeur</h2>
                    <p>
                        Hébergeur: <strong>OVH</strong><br />
                        Adresse: <strong>Roubaix, France</strong><br />
                        SIREN: <strong>424761419</strong>
                    </p>
                </section>

                <section>
                    <h2>Propriété intellectuelle</h2>
                    <p>
                        Le contenu du site (textes, images, graphismes, logo) est la propriété exclusive de <strong>Louis Gaday</strong> et de <strong>Rémy EROES</strong> pour {"l'aspect"} design et développement, sauf mention contraire. Toute reproduction, distribution, modification, adaptation, retransmission ou publication de ces éléments est strictement interdite sans {"l'accord"} écrit préalable de <strong>Louis Gaday</strong> et de <strong>Rémy EROES</strong>.
                    </p>

                    <p>
                        Les icônes provienent du plugin Figma "Iconify" : https://iconify.design.
                    </p>
                </section>

                <section>
                    <h2>Protection des données personnelles</h2>
                    <p>
                        Conformément au Règlement Général sur la Protection des Données (RGPD), vous avez un droit {"d'accès"}, de rectification, et de suppression des données vous concernant. Toutefois, veuillez noter {"qu'il n'existe qu'un espace"} administrateur accessible uniquement au gérant du site. Pour exercer votre droit, contactez-nous à <strong>contact@remy-eroes.site</strong>.
                    </p>
                </section>

                <section>
                    <h2>Gestion des données</h2>
                    <p>
                        Ce site {"n'utilise"} pas de cookies pour suivre votre activité. Toutefois, un local storage est utilisé pour gérer {"l'affichage de certaines sections du site afin d'améliorer"} votre expérience utilisateur. Vous pouvez à tout moment vider votre local storage via les paramètres de votre navigateur si vous souhaitez réinitialiser ces préférences.
                    </p>
                </section>

                <section>
                    <h2>Responsabilité</h2>
                    <p>
                        <strong>Louis GADAY et Rémy EROES</strong> décline toute responsabilité en cas de dysfonctionnement du site, {"d'inexactitudes"} ou {"d'omissions"} dans les informations disponibles, ou {"d'intrusion"} extérieure non autorisée sur le site.
                    </p>
                </section>
            </div>)

            :

            (<div className="mentions-legales">
                <button className="back-button" onClick={() => window.location.href = '/about'}>Back</button>
                <h1>Legal Notices</h1>

                <section>
                    <h2>Site Owner</h2>
                    <p>
                        Company Name: <strong>Louis GADAY</strong><br />
                        Headquarters: <strong>145 Chemin de la Garrigue, 34470 Pérols, France</strong><br />
                        SIRET: <strong>92032790500013</strong><br />
                        Company Director: <strong>Louis GADAY</strong>
                    </p>
                </section>

                <section>
                    <h2>Site Editor and Developer</h2>
                    <p>
                        Company Name: <strong>Rémy EROES</strong><br />
                        Headquarters: <strong>168 Rue Frédéric Mistral, 34280 La Grande-Motte, France</strong><br />
                        SIRET: <strong>92921139900019</strong><br />
                        Company Director: <strong>Rémy EROES</strong>
                    </p>
                </section>

                <section>
                    <h2>Hosting Provider</h2>
                    <p>
                        Hosting Provider: <strong>OVH</strong><br />
                        Address: <strong>Roubaix, France</strong><br />
                        SIREN: <strong>424761419</strong>
                    </p>
                </section>

                <section>
                    <h2>Intellectual Property</h2>
                    <p>
                        The content of the site (texts, images, graphics, logo) is the exclusive property of <strong>Louis Gaday</strong> and <strong>Rémy EROES</strong> for the design and development aspects, unless otherwise stated. Any reproduction, distribution, modification, adaptation, retransmission, or publication of these elements is strictly prohibited without the prior written consent of <strong>Louis Gaday</strong> and <strong>Rémy EROES</strong>.
                    </p>

                    <p>
                        The icons are sourced from the Figma plugin "Iconify": https://iconify.design.
                    </p>
                </section>

                <section>
                    <h2>Personal Data Protection</h2>
                    <p>
                        In accordance with the General Data Protection Regulation (GDPR), you have the right to access, rectify, and delete your personal data. However, please note that there is only an administrator area accessible exclusively to the site manager. To exercise your right, please contact us at <strong>contact@remy-eroes.site</strong>.
                    </p>
                </section>

                <section>
                    <h2>Data Management</h2>
                    <p>
                        This site does not use cookies to track your activity. However, local storage is used to manage the display of certain sections of the site to enhance your user experience. You can clear your local storage via your browser settings at any time if you wish to reset these preferences.
                    </p>
                </section>

                <section>
                    <h2>Responsibility</h2>
                    <p>
                        <strong>Louis GADAY and Rémy EROES</strong> disclaim all responsibility in the event of site malfunctions, inaccuracies or omissions in the information available, or unauthorized external intrusion on the site.
                    </p>
                </section>

            </div>)





    );
};

export default MentionsLegales;