export default function inputFileFormatAcceptedFiles(valueToFormat) {

    //si tableau
    if (Array.isArray(valueToFormat)) {

        if (valueToFormat.length === 0) {
            console.error('inputFileFormatAcceptedFiles.js: the array of valueToFormat is empty');
            return ''
        }

        let formattedValue = '';

        valueToFormat.forEach((element, index) => {
            element.replace('.', '');   // remove the dot if it exists
            if (index === 0) {
                formattedValue += '.' + element
            } else {
                formattedValue += ', .' + element
            }
        });
        return formattedValue
    }
    //si string
    else if (typeof valueToFormat === 'string') {
        let stringToArray = valueToFormat.split(',');
        stringToArray.forEach((element, index) => {
            stringToArray[index] = element.trim().replace('.', ''); // remove the dot if it exists and remove white space
            stringToArray[index] = '.' + stringToArray[index];
        })
        return stringToArray.join(', ')

    }
}
