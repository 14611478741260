import React, {useEffect} from 'react';
import { Link } from 'react-router-dom'; // If using React Router
import { useTranslation } from 'react-i18next'; // If using i18n
import '../css/mentions-legales.scss';
import { useLocation } from 'react-router-dom';
import  changeCanonialLink  from '../utils/changeCanonialLink';

const Sitemap = () => {

  const { t, i18n } = useTranslation();

  // set title and meta description
  useEffect(() => {
      const title = t('Plan du site')+' - Louis Gaday';
      document.title = title;
  }, [t])


  const location = useLocation();
  useEffect(() => {
      changeCanonialLink(location.pathname);
  }, [location]);

  return (
    i18n.language === 'fr' ?
    <section className="sitemap">
    <button className="back-button" onClick={() => window.location.href = '/about'}>Retour</button>
      <h1>Plan du site</h1>
      <ul>
        <li><Link to="/">Accueil</Link></li>
        <li><Link to="/about">À propos</Link></li>
        <li><Link to="/projects">Projets</Link></li>

        <li><Link to="/legal-notices">Mentions Légales</Link></li>
        <li><Link to="/gdpr">RGPD</Link></li>
        <li><Link to="/site-map">Plan du site</Link></li>
      </ul>
    </section>
    :
    <section className="sitemap">
    <button className="back-button" onClick={() => window.location.href = '/about'}>Back</button>
      <h1>Sitemap</h1>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About Us</Link></li>
        <li><Link to="/projects">Projects</Link></li>

        <li><Link to="/legal-notices">Legal Notices</Link></li>
        <li><Link to="/gdpr">GDPR</Link></li>
        <li><Link to="/site-map">Sitemap</Link></li>
      </ul>
    </section>

  );
};

export default Sitemap;