import React, { useEffect, useState } from 'react'
import '../css/projectvideolist.css'
import ReactPlayer from 'react-player'





function ProjectsVideosList(props) {

    const projectList = props.projectList
    const [sortedListByImportance, setSortedListByImportance] = useState()



    useEffect(() => {

        function sortProjectListByImportance() {
            const importanceHigh = []
            const importanceMedium = []
            const importanceWeak = []
            const importanceNone = []

            projectList.forEach(project => {
                if (project['IMPORTANCE'] === 'high') importanceHigh.push(project)
                if (project['IMPORTANCE'] === 'medium') importanceMedium.push(project)
                if (project['IMPORTANCE'] === 'weak') importanceWeak.push(project)
                if (project['IMPORTANCE'] === 'none') importanceNone.push(project)
            });

            const sortedList = importanceHigh.concat(importanceMedium, importanceWeak, importanceNone)
            setSortedListByImportance(sortedList)
        }

        function setSizefor16_9() {
            const rootStyles = getComputedStyle(document.documentElement);
            const columns_number = parseInt(rootStyles.getPropertyValue('--grid-columns-nb-video').trim(), 10) || 3;


            const windowWidth = window.innerWidth * 0.95;
            const gap = 20;
            const videoItemWidth = (windowWidth - gap * (columns_number - 1)) / columns_number;

            document.documentElement.style.setProperty('--video-list-item-height', `${videoItemWidth}px`);

            const videoListItemHeight = (videoItemWidth * 9) / 16;
            document.documentElement.style.setProperty('--video-list-item-height', `${videoListItemHeight}px`);

            const videoListContainer = document.querySelector('.videos-list-container');
            if (videoListContainer) {
                videoListContainer.style.gridTemplateColumns = `repeat(${columns_number}, ${videoItemWidth}px)`;

                videoListContainer.style.gridTemplateRows = `repeat(30, ${videoListItemHeight}px)`;

            }
            else {
                setTimeout(() => {
                    setSizefor16_9()
                }, 100);
            }
        }


        sortProjectListByImportance()
        setSizefor16_9()

        window.addEventListener('resize', setSizefor16_9)
        return () => {
            window.removeEventListener('resize', setSizefor16_9)
        }

    }, [projectList])

    // set rows number
    useEffect(() => {
        setTimeout(() => {
        const rootStyles = getComputedStyle(document.documentElement);
        const columns_number = parseInt(rootStyles.getPropertyValue('--grid-columns-nb-video').trim(), 10) || 3;


        const windowWidth = window.innerWidth * 0.95;
        const gap = 20;
        const videoItemWidth = (windowWidth - gap * (columns_number - 1)) / columns_number;

        document.documentElement.style.setProperty('--video-list-item-height', `${videoItemWidth}px`);

        const videoListItemHeight = (videoItemWidth * 9) / 16;
        document.documentElement.style.setProperty('--video-list-item-height', `${videoListItemHeight}px`);

        const videoListContainer = document.querySelector('.videos-list-container');
        if (videoListContainer) {
            videoListContainer.style.gridTemplateColumns = `repeat(${columns_number}, ${videoItemWidth}px)`;

            videoListContainer.style.gridTemplateRows = `repeat(30, ${videoListItemHeight}px)`;
            let lastRow = 0;
            let biggestBottom = 0;
            const videoListItems = document.querySelectorAll('.video-list-item');
            videoListItems.forEach(videoItem => {
                const bottom = videoItem.getBoundingClientRect().bottom;
                if (bottom > biggestBottom) {
                    biggestBottom = bottom
                }
            });
            lastRow = Math.floor((biggestBottom / videoListItemHeight));
            console.log(lastRow)

            videoListContainer.style.gridTemplateRows = `repeat(${lastRow}, ${videoListItemHeight}px)`;
        }
    }, 300);

    }, [])
    

    return (
        <>
            <div className='videos-list-container'>
                {sortedListByImportance && sortedListByImportance.map((project, index) => {

                    const key = `${project['internal_external_video']}-${project['link_or_name_video']}-${index}`;
                    const videoURL = project['link_or_name_video'];

                    if (project['internal_external_video'] === 'internal') {
                        const videoLink = `/videos/${videoURL}`;

                        return (
                            <div key={key} className={`video-list-item ${project['SIZE']}-video`}>
                                <video playsInline controls>
                                    <source src={videoLink} type="video/mp4" />
                                </video>
                            </div>
                        );
                    } else if (project['internal_external_video'] === 'external') {


                        return (
                            <div key={key} className={`video-list-item ${project['SIZE']}-video`}>
                                <ReactPlayer
                                    url={videoURL}
                                    playing={true}
                                    controls={true}
                                    light={true}
                                    width='100%'
                                    height='100%'
                                    playsinline
                                />
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </div>
        </>
    );
}

export default ProjectsVideosList