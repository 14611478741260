
export default function cursorHoverClass(hoverableItems) {

    
    const cursor = document.querySelector('.cursor-container');

    if (!cursor) return;

    hoverableItems.forEach(item => {
        document.querySelectorAll(item).forEach(thisItem => {
            thisItem.addEventListener('mouseover', () => {
                cursor.classList.add('hover');
            });
        });
        document.querySelectorAll(item).forEach(thisItem => {
            thisItem.addEventListener('mouseleave', () => {
                cursor.classList.remove('hover');
            });
        });
    });
}
