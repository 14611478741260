import React, { useState, useEffect } from 'react'
import '../css/projectDoculist.scss';
import ProjectsDocuItem from './ProjectsDocuItem';
import axios from 'axios';
const config = require('../config.json');

function ProjectDocuList() {

  const [documentaires, setDocumentaires] = useState()


  //A CHANGER 
  const documentairesTest = [
    {
      id: 1,
      position: 1,
      title_fr: "Documentaire 1",
      title_en: "Documentary 1",
      descriptions: {
        en: ["directed by Eric Nebot", "docu-series for Netflix"],
        fr: ["wwwwwwwwwwwwréalisé par Eric Nebot", "série documentaire pour Netflix", "réalisé par Eric Nebot", "série documentaire pour Netflix"]
      },
      image_or_video: "image",
      images: ["1724858982568.jpg", "1724858982582.jpg", "1724858982582.jpg", "1724861014793.jpg"]
    },
    {
      id: 2,
      position: 2,
      title_fr: "Documentaire 2",
      title_en: "Documentary 2",
      descriptions: {
        en: ["directed by Eric Nebot", "docu-series for Netflix"],
        fr: ["réalisé par Eric Nebot", "série documentaire pour Netflix"]
      },
      image_or_video: "video",
      images: [],
      video: {
        link: "https://youtu.be/wj8YPd2PuJc?si=NpADiQilcJJdbyI5",
        external_or_internal: "external"
      }
    },
    {
      id: 3,
      position: 3,
      title_fr: "Documentaire 3",
      title_en: "Documentary 3",
      descriptions: {
        en: ["directed by Eric Nebot", "docu-series for Netflix"],
        fr: ["réalisé par Eric Nebot", "série documentaire pour Netflix"]
      },
      image_or_video: "image",
      images: ["1724858982568.jpg", "1724858982582.jpg", "1724861014793.jpg"]
    },
    {
      id: 4,
      position: 4,
      title_fr: "Documentaire 4",
      title_en: "Documentary 4",
      descriptions: {
        en: ["directed by Eric Nebot", "docu-series for Netflix"],
        fr: ["wwwwwwwwwwwwréalisé par Eric Nebot", "série documentaire pour Netflix", "réalisé par Eric Nebot", "série documentaire pour Netflix"]
      },
      image_or_video: "image",
      images: ["1724858982568.jpg", "1724858982582.jpg", "1724861014793.jpg"]
    }
  ]

  useEffect(() => {
    function arrangeDocumentaires(documentaires_before) {
      let documentairesArranged = []
      if (!documentaires_before) {
        return []
      }
      documentaires_before.forEach(documentaire => {

        let images = []
        if (documentaire.images) {
          documentaire.images.forEach(image => {
            images.push(image.name)
          })
        }

        let documentaireArranged = {
          id: documentaire.id,
          position: documentaire.position,
          title_fr: documentaire.title_fr,
          title_en: documentaire.title_en,
          descriptions: {
            en: documentaire.description_en.split("\r\n"),
            fr: documentaire.description_fr.split("\r\n")
          },
          image_or_video: documentaire.image_or_video,
          images: images,
          video: {
            link: documentaire.video_link,
            external_or_internal: documentaire.external_or_internal
          }
        }
        documentairesArranged.push(documentaireArranged)
      })
      return documentairesArranged
    }

    axios.get(config['apiUrl'] + 'api/dashboard/documentaires/all')
      .then(response => {
        setDocumentaires(arrangeDocumentaires(response.data))
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error)
      })
  }, [])

  


  // useEffect(() => {
  //   const documentaireListContainer = document.querySelector('.projects-categorie-DATA-container');
    
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           entry.target.classList.add('show');
  //         } else {
  //           entry.target.classList.remove('show');
  //         }
  //       });
  //     },
  //     {
  //       root: documentaireListContainer,
  //       threshold: 0.5,
  //     }
  //   );
  
  //   // Sélectionner les éléments après un rendu complet
  //   setTimeout(() => {
  //     const items = document.querySelectorAll('.documentaire');
  //     items.forEach((item) => observer.observe(item));
  //   }, 0);
  
  //   // Nettoyage
  //   return () => {
  //     observer.disconnect();
  //   };
  // }, [documentaires]); // Re-run the effect if `documentaires` changes


  return (
    <>
      <div className="documentaire-list-container-big">
        <div className="documentaire-list-container">


          {documentaires && documentaires.map((documentaire, index) => {
            return (
              <ProjectsDocuItem key={index} content={documentaire} />
            )
          })}




        </div>
      </div>
    </>
  )
}

// {documentairesTest.map((documentaire, index) => {
//   return (
//     <ProjectsDocuItem key={index} content={documentaire} />
//   )
// })}

export default ProjectDocuList