export default function cursorCategoriesHoverClass(categories) {

    
    const cursor = document.querySelector('.cursor-container');

    categories.forEach(item => {

        item.addEventListener('mouseover', () => {
            cursor.classList.add('categorie-hover');
        });
        item.addEventListener('mouseleave', () => {
            cursor.classList.remove('categorie-hover');
        });

    });
}
