
export default function cursorClickClass() {

    
    const cursor = document.querySelector('.cursor-container');

    if (!cursor) return;


    document.addEventListener('click', () => {

        cursor.classList.add('click');

        setTimeout(() => {
            cursor.classList.remove('click');
        }, 500);
    });
}
