import React, { useEffect, useState, useCallback, useLayoutEffect } from 'react';
import axios from 'axios';
import '../css/projectphotolist.css';
import tagDefault from '../images/assets/default_tag.svg';
import { useTranslation } from 'react-i18next';
const config = require('../config.json');


function ProjectPhotoList(props) {
  const [photos, setPhotos] = useState([]);
  const [tags, setTags] = useState([]);
  const [sortedListByImportance, setSortedListByImportance] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [projectsPhotos, setProjectsPhotos] = useState([]);
  const [photosLoading, setPhotosLoading] = useState(true);


  const { i18n } = useTranslation();

  useEffect(() => {
    const changeTagsLangage = () => {
      const tagsP = document.querySelectorAll('.tag-name');

      tagsP.forEach(tag => {
        let tagId = parseInt(tag.id.split('-')[2]);

        tags.forEach(tagElement => {
          if (tagElement.id === tagId) {
            if (i18n.language === 'fr' || i18n.language === 'fr-FR') {
              tag.innerHTML = tagElement.tag_name;
            }
            else {
              tag.innerHTML = tagElement[`tag_name_en`];
            }
          }
        });
      });
    }

    changeTagsLangage()
  }, [i18n.language, tags]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const photosResponse = await axios.get(config['apiUrl'] + 'api/photos');
        setPhotos(photosResponse.data);
        console.log("Photos:", photosResponse.data);

        const tagsResponse = await axios.get(config['apiUrl'] + 'api/tags');
        setTags(tagsResponse.data);
        console.log("Tags:", tagsResponse.data);

        const projectsPhotosResponse = await axios.get(config['apiUrl'] + 'api/projectPhotos');
        setProjectsPhotos(projectsPhotosResponse.data);
        console.log("Projects Photos:", projectsPhotosResponse.data);

        setPhotosLoading(false); // Mettre à jour l'état de chargement

      } catch (error) {
        console.error('There was an error fetching the data!', error);
        setPhotosLoading(false); // Mettre à jour l'état de chargement
      }
    };

    fetchData();
  }, []);

  // Calculate and set sizes
  const setSizefor16_9 = useCallback(() => {
    if (photos.length > 0) {
      const rootStyles = getComputedStyle(document.documentElement);
      const columns_number = parseInt(rootStyles.getPropertyValue('--grid-columns-nb').trim(), 10) || 3;

      const windowWidth = window.innerWidth * 0.95;
      const gap = window.innerWidth < 800 ? 10 : 20 ; //marge entre les photos 10 tel, 20 desktop

      let photoItemWidth = 0;
      if (window.innerWidth < 800) {
        photoItemWidth = (windowWidth - gap*2) / columns_number;
      } else if (window.innerWidth < 950) {
        photoItemWidth = (windowWidth - gap * 2) / columns_number;
      }else if (window.innerWidth < 1600) {
        photoItemWidth = (windowWidth - gap * 3) / columns_number;
      } else {
        photoItemWidth = (windowWidth - gap * 4) / columns_number;
      }

      document.documentElement.style.setProperty('--photo-list-item-width', `${photoItemWidth}px`);

      const photoListItemHeight = (photoItemWidth * 9) / 16;
      document.documentElement.style.setProperty('--photo-list-item-height', `${photoListItemHeight}px`);

      const photoListContainer = document.querySelector('.photos-list-container');
      if (photoListContainer) {
        photoListContainer.style.gridTemplateColumns = `repeat(${columns_number}, ${photoItemWidth}px)`;

        // photoListContainer.style.gridTemplateRows = `repeat(10, ${photoListItemHeight}px)`;
      };

      //obtenir la ligne du dernier element 
      const photoListItems = document.querySelectorAll('.photo-item');
      let lastRow = 0;
      // photoListItems.forEach(photoItem => {
      //   const row = Math.floor(photoItem.getBoundingClientRect().top / photoListItemHeight);
      //   if (row > lastRow) {
      //     lastRow = row
      //   }
      // });
      // //marge de 2 lignes
      // lastRow += 2;

      // TEST 2
      // const bottom = 0;
      // photoListItems.forEach(photoItem => {
      //   const bottom = photoItem.getBoundingClientRect().bottom;
      //   console.log(bottom)

      // });

      photoListContainer.style.gridTemplateRows = `repeat(${lastRow}, ${photoListItemHeight}px)`;


    }
  }, [photos]);

  useLayoutEffect(() => {
    window.addEventListener('resize', setSizefor16_9);
    setSizefor16_9();

    return () => {
      window.removeEventListener('resize', setSizefor16_9);
    };
  }, [photos, setSizefor16_9]);

  // Sort photos by importance
  useLayoutEffect(() => {
    if (photos.length > 0) {
      const sortedList = [...photos].sort((a, b) => {
        const importanceOrder = ['high', 'medium', 'weak', 'none'];
        return importanceOrder.indexOf(a.IMPORTANCE) - importanceOrder.indexOf(b.IMPORTANCE);
      });


      setSortedListByImportance(sortedList);
    }
  }, [photos]);

  // Filter photos by selected tags
  useEffect(() => {
    const photoItems = document.querySelectorAll('.photo-item');
    if (selectedTags.length > 0) {
      photoItems.forEach((photoItem) => {
        const tags = photoItem.dataset.tags.split(',');
        let hasTag = false
        selectedTags.forEach(tag => {
          if (tags.includes(tag.toString())) {
            hasTag = true;
          }
        })
        photoItem.style.display = hasTag ? 'block' : 'none';
      });
    } else {
      photoItems.forEach(photoItem => {
        photoItem.style.display = 'block';
      });
    }
  }, [selectedTags]);

  const toggleFilter = (tagId) => {
    setSelectedTags(prevSelectedTags => {
      if (prevSelectedTags.includes(tagId)) {
        return prevSelectedTags.filter(tag => tag !== tagId);
      } else {
        return [...prevSelectedTags, tagId];
      }
    });
  };

  useEffect(() => {
    const buttons = document.querySelectorAll('.filter-button');
    buttons.forEach(button => {
      // parseint pour éviter les erreurs de comparaison
      if (selectedTags.includes(parseInt(button.dataset.tagbutton))) {
        button.classList.add('selected');
      } else {
        button.classList.remove('selected');
      }
    });
  }, [selectedTags]);


  function showText(e) {
    const photoItem = e.currentTarget;
    const photoName = photoItem.querySelector('.photo-name');
    if (photoName) {
      photoName.classList.add('show');
    }

  }

  function hiddenText(e) {
    const photoItem = e.currentTarget;
    const photoName = photoItem.querySelector('.photo-name');
    if (photoName) {
      photoName.classList.remove('show');
    }
  }


  //performances optimisation
  useEffect(() => {

    //si les photos sont pas encore chargées on sort
    if (photosLoading) return;

    const photos_list_container = document.querySelector('.projects-categorie-DATA-container');
    let lastScrollTop = 0; // Position de scroll précédente

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Détecter la direction du défilement
          const currentScrollTop = photos_list_container.scrollTop;
          const isScrollingDown = currentScrollTop > lastScrollTop;
          lastScrollTop = currentScrollTop;

          // Appliquer la classe en fonction du sens du défilement
          if (entry.isIntersecting) {
            if (isScrollingDown) {
              entry.target.classList.add('isVisibleDown');
              entry.target.classList.remove('isVisibleUp');
            } else {
              entry.target.classList.add('isVisibleUp');
              entry.target.classList.remove('isVisibleDown');
            }
          } else {
            entry.target.classList.remove('isVisibleDown', 'isVisibleUp');
          }
        });
      },
      {
        root: photos_list_container,
        threshold: 0.08, // Détecter dès que l'élément entre dans la vue
      }
    );

    // Sélectionner les éléments après un rendu complet
    setTimeout(() => {
      const items = document.querySelectorAll('.photo-item');
      items.forEach((item) => observer.observe(item));
    }, 0);

    // Nettoyage
    return () => {
      observer.disconnect();
    };
  }, [photos, photosLoading]); // Re-run the effect if `photos` changes



  return (
    <>
      <div className="photos-filters-container-big">
        <div className="photos-filters-container">
          {tags && tags.map(tag => (

            <button
              key={tag.id}
              data-tagbutton={tag.id}
              className={`filter-button ${selectedTags.includes(tag.id) ? 'selected' : ''}`}
              onClick={() => toggleFilter(tag.id)}
            >
              {tag.tag_image_name === null ? <img src={tagDefault} alt={tag.tag_name} /> :
                <img src={`/assets/tags/${tag.tag_image_name}`} alt={tag.tag_name} />
              }
              <p className="tag-name" id={tag ? `tag-id-${tag.id}` : 'loading-tag'}>
                {
                  tag && i18n.language
                    ? (i18n.language === 'fr'  ?
                      tag.tag_name : 
                      i18n.language === 'fr-FR' ?
                      tag.tag_name :
                      tag[`tag_name_en`] || tag.tag_name)
                    : 'Loading...'  // Afficher un message d'attente ou de chargement
                }
              </p>
            </button>
          ))}
        </div>
      </div>

      <div className="photos-list-container">

        {sortedListByImportance.length > 0 && projectsPhotos.length > 0 && sortedListByImportance.map(photo => {
          const photoURL = "photographies/" + photo.name_photo;
          const projectPhoto = projectsPhotos.find(project => project.id === photo.photo_project);

          return (
            <div
              key={photo.id}
              className={`photo-item ${photo['SIZE']}-photo`}
              data-tags={photo.tags.join(',')}
              onMouseEnter={(e) => showText(e)}
              onMouseLeave={(e) => hiddenText(e)}
            >
              <img src={photoURL} alt={"photographie " + projectPhoto.project_name + " Louis Gaday photographe et videaste Motpellier Occitanie"} />

              {projectPhoto && (
                <p className="photo-name">{projectPhoto.project_name}</p>
              )}
            </div>
          );
        })
        }
      </div>
    </>
  );
}

export default ProjectPhotoList;