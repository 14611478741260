import React, { useState } from 'react';

export const CursorContext = React.createContext()

export function CursorProvider({ children }) {
   

    const [cursorVisible, setCursorVisible] = useState(true);
    const [reloadCursor, setReloadCursor] = useState(true)

    function hideCursor() {
        setCursorVisible(false)
    }

    function showCursor() {
        setCursorVisible(true)
    }

    function reloadCursorFunction() {
        setReloadCursor(true)
        setTimeout(() => {
            setReloadCursor(false)
        }, 100);
    }

   

    const value = {hideCursor,showCursor, cursorVisible , reloadCursorFunction, reloadCursor};

    return (
        <CursorContext.Provider value={value}>
            {children}
        </CursorContext.Provider>
    );
}