import React, {useRef, useContext} from 'react'

import trash from '../../images/assets/delete.svg';
import modify from '../../images/assets/modify.svg';
import check from '../../images/assets/check.svg';

import { DashboardMODIFContext } from '../../Context/DashboardMODIFContext';


import '../../css/modifDeleteButtons.css';

function ModifDeleteButtons(props) {
    const modifyButton = useRef();
    const saveButton = useRef();

    const handleModify = () => {
        // check if dashboardMODIF is true
        if (dashboardMODIF) {
            alert('Une modification est déjà en cours, veillez la terminer avant de continuer');
        } else {
            dashboardOpenMODIF()
            modifyButton.current.style.display = 'none';
            saveButton.current.style.display = 'block';
            if (props.onModify) {
                props.onModify();
            }
        }
    };

    const handleSave = () => {
        if (props.onSave) {
            props.onSave();
        }
        modifyButton.current.style.display = 'block';
        saveButton.current.style.display = 'none';

        dashboardCloseMODIF()
    };

    const handleDelete = () => {
        if (props.onDelete) {
            props.onDelete();
        }

        dashboardCloseMODIF()
    };

    const {dashboardOpenMODIF, dashboardCloseMODIF, dashboardMODIF } = useContext(DashboardMODIFContext);

    return (
        <div className='modif-delete'>
            <button ref={saveButton} id="save" onClick={handleSave}>
                <img src={check} alt='check' />
            </button>
            <button ref={modifyButton} onClick={handleModify}>
                <img src={modify} alt='modify' />
            </button>
            {props.onDelete && (
                <button onClick={handleDelete}>
                    <img src={trash} alt='delete' />
                </button>
            )}
        </div>
    );
}

export default ModifDeleteButtons;