import React, { useState, useEffect } from 'react';

export const DashboardMODIFContext = React.createContext()

export function DashboardMODIFProvider({ children }) {
   
    const [dashboardMODIF, setDashboardMODIF] = useState(false);

   function dashboardOpenMODIF() {
        setDashboardMODIF(true)
    }

    function dashboardCloseMODIF() {
        setDashboardMODIF(false)
    }


   

    const value = {dashboardOpenMODIF, dashboardCloseMODIF, dashboardMODIF};

    return (
        <DashboardMODIFContext.Provider value={value}>
            {children}
        </DashboardMODIFContext.Provider>
    );
}