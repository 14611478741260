import React, {useEffect, useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/dashboard.css';

import InfosPerso from '../Components/dashboard/InfosPerso';
import DashboardCategories from '../Components/dashboard/DashboardCategories';
import DashboardCategoryContent from '../Components/dashboard/DashboardCategoryContent';

import DashboardLoading from '../Components/dashboard/DashboardLoading';
//import context
import { LoadingContext} from '../Context/LoadingContext';
import { CursorContext } from '../Context/CursorContext';
import { DashboardNavContext } from '../Context/DashboardNavContext';
import { DashboardMODIFContext } from '../Context/DashboardMODIFContext';

const config = require('../config');

const Dashboard = () => {
    const {reloadCursorFunction} = useContext(CursorContext);
    // relaod cursor
    useEffect(() => {
        reloadCursorFunction()
    }, [])

      // set title and meta description
      useEffect(() => {
        const title = 'Dashboard - Louis Gaday';
        document.title = title;
    }, [])


    const navigate = useNavigate();

    const handleLogout = async () => {
        await axios.post(config['apiUrl'] + 'logout', {}, { withCredentials: true });
        sessionStorage.removeItem('token');
        navigate('/login');
    };

    const handleNewBuild = () => {
        addLoadingItem()

        axios.post(config['apiUrl'] + 'api/makeNewBuild', {}, { withCredentials: true })
            .then((response) => {
                removeLoadingItem()
                window.location.reload()
            })
            .catch((error) => {
                console.error('There was an error!', error);
                removeLoadingItem()
            });

    }

    const {isLoading, addLoadingItem, removeLoadingItem} = useContext(LoadingContext);


    const [logoSrc, setlogoSorc] = useState();
    const [contactInfo, setContactInfo] = useState();
    const {dashboardNav, setDashboardNav} = useContext(DashboardNavContext);
    const [categories, setCategories] = useState([]);

     // select nav button
     useEffect(() => {
        if(!categories) return;

        const buttons = document.querySelectorAll('.dashboard-nav button');
        buttons.forEach((button, index) => {
            if (index === dashboardNav) {
                button.classList.add('selected');
            } else {
                button.classList.remove('selected');
            }
        });
    }, [categories,dashboardNav]);

    // fetch contact info
    useEffect(() => {

        const fetchData = async () => {
            try {
                const contactResponse = await axios.get(config['apiUrl'] + 'api/contact');
                setlogoSorc(contactResponse.data[0]['logo_name']);
                setContactInfo(contactResponse.data[0]);

            } catch (error) {
                console.error('There was an error fetching the data!', error);
            }
        };

        const fetchAllCategories = async () => {
            try {
                const categoriesResponse = await axios.get(config['apiUrl'] + 'api/categories/all');
                setCategories(categoriesResponse.data);

            } catch (error) {
                console.error('There was an error fetching the data!', error);
            }
        }

        fetchData();
        fetchAllCategories();
    }, []);


    const {dashboardOpenMODIF, dashboardCloseMODIF, dashboardMODIF } = useContext(DashboardMODIFContext);

    function changeDashboardNav(id) {
        // check if dashboardMODIF is true
        if(dashboardMODIF){
            const confirm = window.confirm('Voulez-vous vraiment quitter ? Les modifications non sauvegardées seront perdues.');
            if(confirm){
                setDashboardNav(id)
                dashboardCloseMODIF()
            }
        }else{
            setDashboardNav(id)
        }
    }


    useEffect(() => {
        const buttons = document.querySelectorAll('.dashboard-nav button');
        buttons.forEach((button, index) => {
            if (index === dashboardNav) {
                button.classList.add('selected');
            } else {
                button.classList.remove('selected');
            }
        });
    }, [dashboardNav]);


    return (
        <div className='dashboard'>
            <div className='dashboard-header'>
                <img src={'/assets/logo/'+logoSrc} alt='logo' onClick={() => window.location.href = '/home'}  />
                <h2>Dashboard</h2>
                <div>
                    <button className="new-build" onClick={handleNewBuild}>NEW BUILD</button>
                    <button className="logout" onClick={handleLogout}>Logout</button>
                </div>
            </div>

            <div className='dashboard-nav'>
                <button onClick={()=>changeDashboardNav(0)}>Infos perso</button>
                <button onClick={()=>changeDashboardNav(1)}>Catégories</button>
                {categories.map((category, index) => (
                    <button key={category.id} onClick={()=>changeDashboardNav(index+2)}>{category.name_french}</button>
                ))}

            </div>

            <div className='dashboard-content'>


            {(dashboardNav === 0) ? <InfosPerso contactInfo={contactInfo} /> : 
            (dashboardNav === 1) ? <DashboardCategories/> :
            <DashboardCategoryContent category={categories[dashboardNav-2]} />}
            </div>


            {isLoading ? <DashboardLoading /> : null}
            
        </div>
    );
};

//  {isLoading ? <DashboardLoading /> : null}

export default Dashboard;
