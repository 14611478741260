export default function parseBool(value) {
    if (typeof value === 'string') {
        value = value.trim().toLowerCase();  // Enlever les espaces et mettre en minuscules
        if (value === 'true' || value === '1') {
            return 1;
        } else if (value === 'false' || value === '0' || value === '') {
            return 0;
        }
    }

    if (typeof value === 'boolean'){
        if (value === true){
            return 1;
        }else   {
            return 0;
        }
    }


    return (value);  // Pour tous les autres types (ex. déjà booléen, null, undefined)
}