import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player'

function ProjectsDocuItem(props) {

    const { i18n } = useTranslation();
    const directory = props.directory ? props.directory : "/documentaires/"
    const slider = useRef(null)
    const slider_container = useRef(null)
    const textDiv = useRef(null)
    const documentaireDiv = useRef(null)

    const [sliderIndex, setSliderIndex] = useState(0)
    const [sliderWidth, setSliderWidth] = useState(0)
    const [imagesWidth, setImagesWidth] = useState([])
    const [images, setImages] = useState([])
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const CAROUSELONMOUSEOVER = isTouchDevice ? false : true
    const [pause, setPause] = useState(true)
    const [animLancee, setAnimLancee] = useState(false)
    const [reloadSliderImagesWidth, setReloadSliderImagesWidth] = useState(false)


    // Détecter les appareils tactiles
    useEffect(() => {
        setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }, []);

    // doubler les images avant tout 
    useLayoutEffect(() => {
        // Doubler les images pour l'effet de slider infini
        const imagesprops = props.content.images
        // setImages(imagesprops.concat(imagesprops))
        setImages(imagesprops)
    }, [props.content.images])



    //  slider
    useEffect(() => {

        function slider() {

            ////////////// RELOAD IMAGES WIDTH POUR GERER PROBLEMES DI IMAGES PAS CHARGÉES            
            const slider = slider_container.current.querySelector('.documentaire-images-container-slider')

            const imageElements = slider.querySelectorAll('.documentaire-image')
            const sliderWidth = slider_container.current.clientWidth

            const imagesWidth = Array.from(imageElements).map(image => image.clientWidth);

            setImagesWidth(imagesWidth)
            setSliderWidth(sliderWidth)
            /////////////////////////

            if (sliderIndex === imagesWidth.length - 1) {
                setSliderIndex(0)

            } else {
                setSliderIndex((sliderIndex + 1))
            }

            let translateValue = 0
            imagesWidth.forEach((width, index) => {
                if (index < sliderIndex) {
                    translateValue += width
                }
            })

            let marginLeftForCenter = (sliderWidth - imagesWidth[sliderIndex]) / 2
            translateValue -= marginLeftForCenter




            slider.style.transform = `translateX(${-translateValue}px)`




        }

        if (props.content.image_or_video === "image" && !pause) {

            // interval pour slider entre 2 et 4 secondes en fonction de sliderIndex.length _> plus il y a de photos plus le slider est rapide
            // const intervalTime = 2000 + Math.floor(imagesWidth.length/10 * 1000)
            if (!animLancee) {
                slider()
                setAnimLancee(true)
            }
            else {
                const intervalTime = 2000

                const interval = setInterval(slider, intervalTime)
                return () => clearInterval(interval)
            }


        }
    }, [imagesWidth, props.content.image_or_video, sliderIndex, pause, sliderWidth, animLancee])


    // set width images
    useEffect(() => {

        function setWidth() {
            if (!slider.current) {
                return
            }
            const imageElements = slider.current.querySelectorAll('.documentaire-image')
            const sliderWidth = slider_container.current.clientWidth

            const imagesWidth = Array.from(imageElements).map(image => image.clientWidth);

            setImagesWidth(imagesWidth)
            setSliderWidth(sliderWidth)

            // Réinitialiser le slider
            setSliderIndex(0)
            // if (!CAROUSELONMOUSEOVER) {
            //     setPause(false)
            // }
        }

        setPause(true)
        setTimeout(() => {
            setWidth()
        }, 100);
    }, [reloadSliderImagesWidth, CAROUSELONMOUSEOVER])

    //resize
    useEffect(() => {
        setReloadSliderImagesWidth(true)
    }, [window.innerWidth])


    // size margin bottom pour montrer le texte
    useEffect(() => {

        function setMarginBottom() {
            if (!textDiv.current) {
                return
            }

            const text = textDiv.current
            const textHeight = text.clientHeight

            const documentaire = documentaireDiv.current
            documentaire.style.marginBottom = textHeight + "px"

        }

        setMarginBottom()


    }, [window.innerWidth, i18n.language])



    const handleMouseEnter = () => {
        setReloadSliderImagesWidth(true)
        if (CAROUSELONMOUSEOVER) {
            setPause(false)
        }

        if (!documentaireDiv) {
            return
        }

        const documentaire = documentaireDiv.current
        documentaire.classList.add('hover')
    }
    const handleMouseLeave = () => {
        setReloadSliderImagesWidth(true)
        if (CAROUSELONMOUSEOVER) {
            setPause(true)
            setAnimLancee(false)
        }

        if (!documentaireDiv) {
            return
        }

        const documentaire = documentaireDiv.current
        documentaire.classList.remove('hover')
    }


    // language
    useEffect(() => {
        function textFromLangague(langage) {
            const h2 = textDiv.current.querySelector('h2')
            h2.textContent = props.content["title_" + langage]
            const p = textDiv.current.querySelectorAll('p')
            //remove all p
            p.forEach((el) => {
                el.remove()
            })
            //add all p
            props.content.descriptions[langage].forEach((description, index) => {
                const p = document.createElement('p')
                p.textContent = description
                textDiv.current.appendChild(p)
            })
        }

        if (i18n.language === "fr-FR" || i18n.language === "fr") {
            textFromLangague("fr")
        } else {
            textFromLangague("en")
        }

    }, [i18n.language, props.content])


    // Intersection Observer pour les appareils tactiles pour reagir au scroll
    useEffect(() => {
        if (isTouchDevice) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setPause(false);  // Reprendre la lecture
                        } else {
                            setPause(true);  // Mettre en pause
                        }
                    });
                },
                {
                    threshold: 0.5,  // Vous pouvez ajuster ce seuil en fonction de vos besoins
                }
            );

            if (documentaireDiv.current) {
                observer.observe(documentaireDiv.current);
            }

            // Nettoyage
            return () => {
                if (documentaireDiv.current) {
                    observer.unobserve(documentaireDiv.current);
                }
            };
        }
    }, [isTouchDevice]);




    return (
        <div key={props.content.id} className="documentaire" ref={documentaireDiv} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>

            {props.content.image_or_video === "image" ? (
                <div className="documentaire-images-container" ref={slider_container}>
                    <div className="documentaire-images-container-slider" ref={slider}>
                        {images.map((image, index) => {

                            let isVisible
                            if (pause) {
                                isVisible = true
                            } else {
                                isVisible = index === sliderIndex - 1
                            }



                            return (
                                <img
                                    key={index}
                                    className={`documentaire-image ${isVisible ? 'show' : ''}`}
                                    src={directory + image}
                                    alt={"documentaire "+props.content["title_" + i18n.language] + " Louis Gaday photographe et videaste Motpellier Occitanie"}
                                />
                            );
                        })}
                    </div>
                </div>
            ) : (
                <div className="documentaire-video-container">
                    {props.content.video.external_or_internal === "external" ? (
                        <ReactPlayer
                            url={props.content.video.link}
                            playing={false}
                            controls={true}
                            light={true}
                            width="100%"
                            height="100%"
                            playsinline
                        />
                    ) : null}
                </div>
            )}

            <div className="documentaire-text" ref={textDiv}>
                {i18n.language && (
                    <>
                        <h2>{props.content["title_" + i18n.language]}</h2>
                        {props.content.descriptions[i18n.language] &&
                            props.content.descriptions[i18n.language].map((description, index) => (
                                <p key={index} className="documentaire-description-line">
                                    {description}
                                </p>
                            ))}
                    </>
                )}
            </div>
        </div>
    )
}

export default ProjectsDocuItem