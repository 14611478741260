import React from 'react';
import '../../../css/ImportanceSizeSelector.css';
import axios from 'axios';
import config from '../../../config';

const ImportanceSizeSelector = (props) => {
    const importanceStates = ['none', 'weak', 'medium', 'high'];
    const sizeStates = ['portrait', 'normal', 'big'];


    const handleImportanceClick = (e) => {
        if (props.type === 'importance') {
            const selectedImportance = e.currentTarget.getAttribute('data-importance');

            const parentDiv = e.currentTarget.closest('.photos-in-projet');
            let projectPhotoID = parseInt(parentDiv.id.split('-')[parentDiv.id.split('-').length - 1])

            const importantSelectorDiv = e.currentTarget.closest('.importance-selector');
            const importanceDivs = importantSelectorDiv.querySelectorAll('.importance');

            importanceDivs.forEach((importanceDiv) => {
                importanceDiv.classList.remove('selected');
            });
            e.currentTarget.classList.add('selected');

            const formData = new FormData()
            formData.append('importance', selectedImportance)
            formData.append('projectPhotoID', projectPhotoID)

            axios.post(config['apiUrl'] + '/api/changePhotoImportance', formData, { withCredentials: true })
                // if erreur 500
                .catch((error) => {
                    alert('Erreur lors du changement de l\'importance de la photo');
                });
        } else if (props.type === 'size') {
            const selectedSize = e.currentTarget.getAttribute('data-size');

            const parentDiv = e.currentTarget.closest('.photos-in-projet');
            let projectPhotoID = parseInt(parentDiv.id.split('-')[parentDiv.id.split('-').length - 1])

            const sizeSelectorDiv = e.currentTarget.closest('.size-selector');
            const sizeDivs = sizeSelectorDiv.querySelectorAll('.size');

            sizeDivs.forEach((sizeDiv) => {
                sizeDiv.classList.remove('selected');
            });
            e.currentTarget.classList.add('selected');

            const formData = new FormData()
            formData.append('size', selectedSize)
            formData.append('projectPhotoID', projectPhotoID)

            axios.post(config['apiUrl'] + '/api/changePhotoSize', formData, { withCredentials: true })
                // if erreur 500
                .catch((error) => {
                    alert('Erreur lors du changement de la taille de la photo');
                });
        }

    }




    return (
        props.type === 'importance' ?

            <div className='input-item'>
                <p>Importance: </p>
                <div className='importance-selector'>
                    {importanceStates.map((importanceState, index) => {
                        const selected = importanceState === props.content.IMPORTANCE;
                        // const selected = false;
                        return (
                            <div
                                className={`importance ${importanceState} ${selected ? 'selected' : ''}`}
                                onClick={handleImportanceClick}
                                data-importance={importanceState}
                                key={index}
                            >
                                <p>{importanceState}</p>
                            </div>
                        );
                    })}
                </div>
            </div>

            :

            <div className='input-item'>
                <p>Size: </p>
                <div className='size-selector'>
                    {sizeStates.map((sizeState, index) => {
                        const selected = sizeState === props.content.SIZE;
                        return (
                            <div
                                className={`size ${sizeState} ${selected ? 'selected' : ''}`}
                                onClick={handleImportanceClick}
                                data-size={sizeState}
                                key={index}
                            >
                                <p>{sizeState}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
    );
};

export default ImportanceSizeSelector;


//   {importanceStates.map((importanceState, index) => {
//     const selected = importanceState === props.content.IMPORTANCE;
//     return (
//         <div
//             className={`importance ${importanceState} ${selected ? 'selected' : ''}`}
//             onClick={handleImportanceClick}
//             data-importance={importanceState}
//             key={index}
//         >
//             <p>{importanceState}</p>
//         </div>
//     );
// })}