import React, { useState, useEffect, useContext, useRef } from 'react'
import '../../../css/dashboardCategoryContentLine.scss'
import axios from 'axios'
import config from '../../../config.json'
import ModifDeleteButtons from '../ModifDeleteButtons'
import ReactPlayer from 'react-player'
import { LoadingContext } from '../../../Context/LoadingContext'
import ImportanceSizeSelector from './ImportanceSizeSelector'

function DashboardCategoryContentLine(props) {

    const { addLoadingItem, removeLoadingItem, isLoading } = useContext(LoadingContext)

    const [modifState, setModifState] = useState(false)
    const [reload, setReload] = useState(false)
    const [allTags, setAllTags] = useState([])

    const selectOptions = {
        importance: ['none', 'weak', 'medium', 'high'],
        size_videos: ['reel', 'normal', 'big'],
        "size_photos-in-projet": ['portrait', 'normal', 'big'],
        links: ['internal', 'external']
    }
    // REF VIDEOS
    const selectExternalInternal = useRef(null)
    const nameFRInput = useRef(null)
    const nameENInput = useRef(null)
    const linkInputFile = useRef(null)
    const linkInputText = useRef(null)
    const importanceSelect = useRef(null)
    const sizeSelect = useRef(null)
    const [internalExternal, setInternalExternal] = useState('internal')

    //REF PHOTOGRAPHIES
    const nameFRInputTag = useRef(null)
    const nameENInputTag = useRef(null)
    const InputFileTag = useRef(null)

    // REF PHOTOGRAPHIES PROJETS
    const nameFRInputProjetPhoto = useRef(null)
    const nameENInputProjetPhoto = useRef(null)
    // const importanceSelectPhotos = useRef(null)
    const InputFilePhoto = useRef(null)
    // const sizeSelectPhotos = useRef(null)

    //REF TAGS dans les photos
    const tagsDiv = useRef(null)
    const tagsContainer = useRef(null)

    // DROPZONE
    const InputFilePhotosDropZone = useRef(null)

    //ref documentaires
    const titleFRInputDocu = useRef(null)
    const titleENInputDocu = useRef(null)
    const descriptionFRInputDocu = useRef(null)
    const descriptionENInputDocu = useRef(null)
    const positionFRInputDocu = useRef(null)
    const typeSelectDocu = useRef(null)

    const typeSelectDocuOptions = ['image', 'video']
    const [description_fr, setDescription_fr] = useState(props.structure === 'documentaires' ? props.content.description_fr : '')
    const [description_en, setDescription_en] = useState(props.structure === 'documentaires' ? props.content.description_en : '')


    //docuvideos
    const [internalExternalDocuVideo, setInternalExternalDocuVideo] = useState(props.content.external_or_internal)
    const linkInputFileDocuVideo = useRef(null)
    const linkInputTextDocuVideo = useRef(null)
    const selectExternalInternalDocuVideo = useRef(null)


    //docuImage
    const InputFilePhotosDropZoneDocuImage = useRef(null)
    const positionDocuImage = useRef(null)
    const InputFilePhotoDocuImage = useRef(null)



    function modifClick() {
        setModifState(!modifState)
    }

    function save() {

        if (props.structure === 'photographie-tags') {

            const id = props.content.id
            const InputFile = InputFileTag.current.files[0] ? InputFileTag.current.files[0] : null
            const nameFRInput = nameFRInputTag.current.value ? nameFRInputTag.current.value : nameFRInputTag.current.placeholder
            const nameENInput = nameENInputTag.current.value ? nameENInputTag.current.value : nameENInputTag.current.placeholder

            const formData = new FormData()
            formData.append('nomFR', nameFRInput)
            formData.append('nomEN', nameENInput)

            addLoadingItem()
            axios.post(config['apiUrl'] + 'api/updateTag/' + id, formData, { withCredentials: true })
                .then((response) => {
                    if (InputFile) {
                        let file = InputFileTag.current.files[0]

                        let formData = new FormData()
                        formData.append('file', file)

                        addLoadingItem()
                        axios.post(config['apiUrl'] + 'api/updateTagImage/' + id, formData, { withCredentials: true })
                            .then((response) => {
                                removeLoadingItem()
                                setModifState(false)
                                setReload(!reload)
                                window.location.reload()
                            })
                            .catch((error) => {
                                console.error('There was an error!', error);
                            });
                    } else {
                        removeLoadingItem()
                        setModifState(false)
                        setReload(!reload)
                        window.location.reload()
                    }

                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });

        } else if (props.structure === 'videos') {

            const nomFR = nameFRInput.current.value ? nameFRInput.current.value : nameFRInput.current.placeholder
            const nomEN = nameENInput.current.value ? nameENInput.current.value : nameENInput.current.placeholder
            const linkType = selectExternalInternal.current.value ? selectExternalInternal.current.value : 'internal'
            const linkText = (linkType === 'external') ? linkInputText.current.value ? linkInputText.current.value : linkInputText.current.placeholder : ''
            // const linkFile = (linkType === 'internal') ? linkInputFile.current.files[0] ? linkInputFile.current.files[0] : null : null
            const importance = importanceSelect.current.value ? importanceSelect.current.value : 'none'
            const size = sizeSelect.current.value ? sizeSelect.current.value : 'normal'

            // console.log(nomFR, nomEN, linkType, linkText, importance, size)

            const formData = new FormData()
            formData.append('nomFR', nomFR)
            formData.append('nomEN', nomEN)
            formData.append('linkType', linkType)
            formData.append('linkText', linkText)
            formData.append('importance', importance)
            formData.append('size', size)


            axios.post(config['apiUrl'] + 'api/projects/videos/update/' + props.content.id, formData, { withCredentials: true })
                .then((response) => {

                    if (linkType === 'internal' && linkInputFile.current.files[0]) {
                        let file = linkInputFile.current.files[0]
                        let formData = new FormData()
                        formData.append('file', file)

                        addLoadingItem()


                        axios.post(config['apiUrl'] + 'api/projects/videos/updateFile/' + props.content.id, formData, { withCredentials: true })
                            .then((response) => {
                                removeLoadingItem()
                                setModifState(false)
                                setReload(!reload)
                                window.location.reload()
                            })
                            .catch((error) => {
                                console.error('There was an error!', error);
                            });
                    } else {
                        setModifState(false)
                        setReload(!reload)
                        window.location.reload()
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });
        } else if (props.structure === 'photographies') {
            const nomFR = nameFRInputProjetPhoto.current.value ? nameFRInputProjetPhoto.current.value : nameFRInputProjetPhoto.current.placeholder
            const nomEN = nameENInputProjetPhoto.current.value ? nameENInputProjetPhoto.current.value : nameENInputProjetPhoto.current.placeholder

            const formData = new FormData()
            formData.append('nomFR', nomFR)
            formData.append('nomEN', nomEN)

            axios.post(config['apiUrl'] + 'api/updatePhotoProject/' + props.content.id, formData, { withCredentials: true })
                .then((response) => {
                    setModifState(false)
                    setReload(!reload)
                    window.location.reload()
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });


        } else if (props.structure === 'photos-in-projet') {

            const id = props.content.id
            const tagsIDs = []
            Array.from(tagsContainer.current.children).forEach(tagDiv => {
                if (tagDiv.classList.contains('selected')) {
                    tagsIDs.push(parseInt(tagDiv.dataset.tagId));
                }
            });

            // obtenir la position de scroll dans dashboard-category-container
            const scrollPosition = document.querySelector('.dashboard-category-container').scrollTop
            console.log(scrollPosition)
            // mettre dans le local storage la position de scroll pour la réutiliser après le rechargement de la page
            localStorage.setItem('scrollPosition-dashboard-category-container', scrollPosition)

            // const importance = importanceSelectPhotos.current.value ? importanceSelectPhotos.current.value : 'none'
            // const size = sizeSelectPhotos.current.value ? sizeSelectPhotos.current.value : 'normal'
            const file = InputFilePhoto.current.files[0] ? InputFilePhoto.current.files[0] : null

            const formData = new FormData()
            // formData.append('importance', importance)
            // formData.append('size', size)
            formData.append('tags', JSON.stringify(tagsIDs))

            addLoadingItem()

            axios.post(config['apiUrl'] + 'api/updatePhoto/' + id, formData, { withCredentials: true })
                .then((response) => {
                    if (file) {
                        let formData = new FormData()
                        formData.append('file', file)

                        addLoadingItem()
                        axios.post(config['apiUrl'] + 'api/updatePhotoFile/' + id, formData, { withCredentials: true })
                            .then((response) => {
                                removeLoadingItem()
                                setModifState(false)
                                setReload(!reload)
                                // window.location.reload()
                            })
                            .catch((error) => {
                                console.error('There was an error!', error);
                            });
                    }
                    else {
                        removeLoadingItem()
                        setModifState(false)
                        // window.location.reload()
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });


        } else if (props.structure === 'documentaires') {


            const id = props.content.id
            const titreFr = titleFRInputDocu.current.value ? titleFRInputDocu.current.value : titleFRInputDocu.current.placeholder
            const titreEn = titleENInputDocu.current.value ? titleENInputDocu.current.value : titleENInputDocu.current.placeholder
            const descriptionFr = descriptionFRInputDocu.current.value ? descriptionFRInputDocu.current.value : descriptionFRInputDocu.current.placeholder
            const descriptionEn = descriptionENInputDocu.current.value ? descriptionENInputDocu.current.value : descriptionENInputDocu.current.placeholder
            const position = positionFRInputDocu.current.value ? positionFRInputDocu.current.value : positionFRInputDocu.current.placeholder
            const type = typeSelectDocu.current.value ? typeSelectDocu.current.value : 'image'
            let changetype = false

            if (type !== props.content.image_or_video) {
                const confirm = window.confirm('Vous êtes sur le point de changer le type de ce documentaire, cela supprimera tout le contenu de ce documentaire ?')
                if (!confirm) {
                    window.location.reload()
                }
                changetype = true

            }



            const formData = new FormData()
            formData.append('titreFr', titreFr)
            formData.append('titreEn', titreEn)
            formData.append('descriptionFr', descriptionFr)
            formData.append('descriptionEn', descriptionEn)
            formData.append('position', position)
            formData.append('type', type)
            formData.append('changetype', changetype)

            axios.post(config['apiUrl'] + 'api/documentaire/update/' + id, formData, { withCredentials: true })
                .then((response) => {
                    setModifState(false)
                    window.location.reload()
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });
        } else if (props.structure === 'docu-video') {
            const id = props.content.id
            const linkType = selectExternalInternalDocuVideo.current.value ? selectExternalInternalDocuVideo.current.value : 'internal'
            const linkText = (linkType === 'external') ? linkInputTextDocuVideo.current.value ? linkInputTextDocuVideo.current.value : linkInputTextDocuVideo.current.placeholder : ''
            const linkFile = (linkType === 'internal') ? linkInputFileDocuVideo.current.files[0] ? linkInputFileDocuVideo.current.files[0] : null : null


            const formData = new FormData()
            formData.append('linkType', linkType)
            formData.append('linkText', linkText)

            axios.post(config['apiUrl'] + 'api/documentaire/updateVideoText/' + id, formData, { withCredentials: true })
                .then((response) => {
                    if (linkType === 'internal' && linkInputFileDocuVideo.current.files[0]) {
                        let file = linkInputFileDocuVideo.current.files[0]
                        let formData = new FormData()
                        formData.append('file', file)

                        addLoadingItem()

                        axios.post(config['apiUrl'] + 'api/documentaire/updateVideoFile/' + id, formData, { withCredentials: true })
                            .then((response) => {
                                removeLoadingItem()
                                setModifState(false)
                                window.location.reload()
                            })
                            .catch((error) => {
                                console.error('There was an error!', error);
                            });
                    } else {
                        window.location.reload()
                    }

                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });
        } else if (props.structure === 'docu-photo') {
            const id = props.content.id
            const position = positionDocuImage.current.value ? positionDocuImage.current.value : positionDocuImage.current.placeholder
            const file = InputFilePhotoDocuImage.current.files[0] ? InputFilePhotoDocuImage.current.files[0] : null

            const formData = new FormData()
            formData.append('position', position)

            axios.post(config['apiUrl'] + 'api/documentaire/updateImagePosition/' + id, formData, { withCredentials: true })
                .then((response) => {
                    if (file) {
                        let formData = new FormData()
                        formData.append('file', file)

                        addLoadingItem()
                        axios.post(config['apiUrl'] + 'api/documentaire/updateImageFile/' + id, formData, { withCredentials: true })
                            .then((response) => {
                                removeLoadingItem()
                                setModifState(false)
                                window.location.reload()
                            })
                            .catch((error) => {
                                console.error('There was an error!', error);
                            });
                    } else {
                        setModifState(false)
                        window.location.reload()
                    }
                })
        }
    }

    function deleteItem() {
        let deleteConfirm = window.confirm('Voulez-vous vraiment supprimer cet élément ?')
        if (!deleteConfirm) {
            return
        }

        let deleteUrl = ''

        switch (props.structure) {
            case 'videos':
                deleteUrl = 'api/deleteProject/'
                break;

            case 'photographie-tags':
                deleteUrl = 'api/deleteTag/'
                break;

            case 'photographies':
                deleteUrl = 'api/deleteProjectPhoto/'
                break;

            case 'photos-in-projet':
                deleteUrl = 'api/deletePhoto/'
                break;

            case 'documentaires':
                deleteUrl = 'api/deleteDocumentaire/'
                break

            case 'docu-photo':
                deleteUrl = 'api/deleteDocumentaireImage/'
                break

            default:
                break;
        }


        // axios
        axios.delete(config['apiUrl'] + deleteUrl + props.content.id)
            .then((response) => {
                hideItemById(props.content.id)
            })
            .catch((error) => {
                console.error('There was an error!', error);
            });
    }

    function hideItemById(id) {
        let item = document.getElementById(`dashboard-${props.structure}-` + id)
        console.log(item)
        item.classList.add('hidden')
    }

    // VIDEOS Select useeffect
    useEffect(() => {
        if (selectExternalInternal.current) {
            setInternalExternal(selectExternalInternal.current.value)
        }
    }, [selectExternalInternal, modifState])


    // console.dir(props.content, { depth: null, colors: true });

    // fetch all tags
    useEffect(() => {
        axios.get(config['apiUrl'] + 'api/tags')
            .then((response) => {
                setAllTags(response.data)
            })
            .catch((error) => {
                console.error('There was an error!', error);
            });
    }, [])

    function handleTagClick(e) {
        let tagDiv = e.currentTarget
        if (tagDiv.classList.contains('selected')) {
            tagDiv.classList.remove('selected')
        } else {
            tagDiv.classList.add('selected')
        }
        // console.log(tagDiv)

        const setTagInDB = (tagDiv) => {
            const tagID = parseInt(tagDiv.dataset.tagId)

            // element parent qui a les classname dashboard-category-content photos-in-projet
            let parentElement = tagDiv.closest('.photos-in-projet')
            let projectPhotoID = parseInt(parentElement.id.split('-')[parentElement.id.split('-').length - 1])

            const setOrUnset = tagDiv.classList.contains('selected') ? 'set' : 'unset'

            const formData = new FormData()
            formData.append('tagID', tagID)
            formData.append('projectPhotoID', projectPhotoID)

            if (setOrUnset === 'set') {
                axios.post(config['apiUrl'] + '/api/addTagToPhoto/', formData, { withCredentials: true })
                    // if erreur 500
                    .catch((error) => {
                        alert('Erreur lors de l\'ajout du tag à la photo')
                    });
            } else if (setOrUnset === 'unset') {
                axios.post(config['apiUrl'] + '/api/removeTagFromPhoto/', formData, { withCredentials: true })
                    // if erreur 500
                    .catch((error) => {
                        alert('Erreur lors de la suppression du tag à la photo')
                    });
            }
        }

        setTagInDB(tagDiv)
    }


    // DROPZONE
    const handleFileSelect = (event) => {
        const files = event.target.files;
        handleFiles(files);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        handleFiles(files);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.currentTarget.classList.add('dragover');
    };

    const handleDragEnter = (event) => {
        event.currentTarget.classList.add('dragover');
    };

    const handleDragLeave = (event) => {
        event.currentTarget.classList.remove('dragover');
    };

    const handleFiles = (files) => {
        // Convert the FileList to an array and do something with the files
        const fileArray = Array.from(files);
        let fileString = ''
        fileArray.forEach(file => {
            fileString += file.name + ', '
        })

        const confirm = window.confirm('Voulez-vous vraiment ajouter ces photos ?' + fileString)
        if (confirm) {

            fileArray.forEach(file => {
                const formData = new FormData();
                formData.append('file', file);

                addLoadingItem()


                let urlAPI = ''
                switch (props.structure) {
                    case 'documentaires':
                        urlAPI = 'api/documentaire/' + props.content.id + '/addImage'
                        break
                    case 'photographies':
                        urlAPI = 'api/projectPhoto/' + props.content.id + '/addPhoto'
                        break;

                    default:
                        break
                }


                axios.post(config['apiUrl'] + urlAPI, formData, { withCredentials: true })
                    .then((response) => {
                        removeLoadingItem()
                        if (!isLoading) {
                            window.location.reload()
                        }
                        setReload(!reload)
                    })
                    .catch((error) => {
                        console.error('There was an error!', error);
                    });
            })
        }

    };


    // scroll to the last position
    useEffect(() => {
        const scrollPosition = localStorage.getItem('scrollPosition-dashboard-category-container')
        if (scrollPosition) {
            //scroll to the last position avec un peu de marge en haut
            document.querySelector('.dashboard-category-container').scrollTo(0, scrollPosition)
            //remettre a null
            setTimeout(() => {
                localStorage.setItem('scrollPosition-dashboard-category-container', null)
            }, 200);
        }
    }, [])

    return (

        (props.structure === 'videos' ?
            (<div className={`dashboard-category-content ${props.structure} ${props.content.isNew ? 'is-new' : ''}`} id={`dashboard-${props.structure}-` + props.content.id} key={props.content.id}>
                <div className='dashboard-category-video'>
                    {modifState ?

                        <div className="inputs">
                            <div className='input-item' >
                                <p>link: </p>
                                <select ref={selectExternalInternal} onChange={(e) => setInternalExternal(e.target.value)} >
                                    {selectOptions.links.map((option, index) => {
                                        let selected = (option === props.content.internal_external_video) ? true : false
                                        return (
                                            <option key={index} value={option} selected={selected} >{option}</option>)
                                    })}
                                </select>
                            </div>

                            {internalExternal === 'internal' ?

                                <input type='file' ref={linkInputFile} />
                                :
                                <input type='text' placeholder={props.content.link_or_name_video} ref={linkInputText} />
                            }

                        </div>

                        :

                        <div className='video-container'>
                            <ReactPlayer url={props.content.link_or_name_video} playing={false} controls={true} light={true} width='100%' height='100%' playsinline />
                        </div>


                    }
                </div>


                <div className='dashboard-category-name'>
                    {modifState ?
                        <>
                            <div className='input-item'>
                                <p>Nom: </p>
                                <input type='text' ref={nameFRInput} placeholder={props.content.project_name} />
                            </div>
                            <div className='input-item'>
                                <p>Nom [en]: </p>
                                <input type='text' ref={nameENInput} placeholder={props.content.project_name} />
                            </div>
                        </>
                        :
                        <h3><span>Nom:</span> {props.content.project_name}</h3>
                    }
                </div>

                {props.structure === 'videos' ?
                    <div className='dashboard-category-size'>
                        {modifState ?
                            <>
                                <div className='input-item'>
                                    <p>Importance: </p>
                                    <select ref={importanceSelect}>
                                        {selectOptions.importance.map((option, index) => {
                                            let selected = (option === props.content.IMPORTANCE) ? true : false
                                            return (
                                                <option key={index} value={option} selected={selected} >{option}</option>)
                                        })}
                                    </select>
                                </div>
                                <div className='input-item'>
                                    <p>Size: </p>
                                    <select ref={sizeSelect}>
                                        {selectOptions['size_' + props.structure].map((option, index) => {
                                            let selected = (option === props.content.SIZE) ? true : false
                                            return (
                                                <option key={index} value={option} selected={selected} >{option}</option>)
                                        })}
                                    </select>
                                </div>
                            </>

                            :
                            (props.content.IMPORTANCE !== 'none' ?
                                <p className='importance'>{props.content.IMPORTANCE}</p>
                                :
                                null
                            )

                        }
                    </div>
                    :
                    null
                }


                <ModifDeleteButtons onModify={modifClick} onSave={save} onDelete={deleteItem} />
            </div>)
            :
            (props.structure === 'photographie-tags' ?
                (<div className={`dashboard-category-content ${props.structure} ${props.content.isNew ? 'is-new' : ''}`} id={`dashboard-${props.structure}-` + props.content.id} key={props.content.id}>
                    <div className='dashboard-category-image'>
                        {modifState ?

                            <div className="inputs">
                                <input type='file' accept='.svg' ref={InputFileTag} />

                            </div>

                            :

                            <div className='image-container'>
                                <img src={'/assets/tags/' + props.content.tag_image_name} alt={props.content.tag_name} />
                            </div>


                        }
                    </div>


                    <div className='dashboard-category-name'>
                        {modifState ?
                            <>
                                <div className='input-item'>
                                    <p>Nom: </p>
                                    <input type='text' ref={nameFRInputTag} placeholder={props.content.tag_name} />
                                </div>
                                <div className='input-item'>
                                    <p>Nom [en]: </p>
                                    <input type='text' ref={nameENInputTag} placeholder={props.content.tag_name_en} />
                                </div>
                            </>
                            :
                            <h3><span>Nom:</span> {props.content.tag_name}</h3>
                        }
                    </div>


                    <ModifDeleteButtons onModify={modifClick} onSave={save} onDelete={deleteItem} />
                </div>)

                :
                (props.structure === 'photographies' ?
                    (<>
                        <div className={`dashboard-category-content ${props.structure} ${props.content.isNew ? 'is-new' : ''}`} id={`dashboard-${props.structure}-` + props.content.id} key={props.content.id}>

                            <div className='dashboard-category-name'>
                                {modifState ?
                                    <>
                                        <div className='input-item'>
                                            <p>Nom: </p>
                                            <input type='text' ref={nameFRInputProjetPhoto} placeholder={props.content.project_name} />
                                        </div>
                                        <div className='input-item'>
                                            <p>Nom [en]: </p>
                                            <input type='text' ref={nameENInputProjetPhoto} placeholder={props.content.project_name_en} />
                                        </div>
                                    </>
                                    :
                                    <h3><span>Nom:</span> {props.content.project_name}</h3>
                                }
                            </div>


                            <ModifDeleteButtons onModify={modifClick} onSave={save} onDelete={deleteItem} />

                        </div>

                        {modifState && (
                            <div
                                className='dropzone'
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                            >
                                <p>Drag & drop files here, or click to select files</p>
                                <input
                                    type='file'
                                    ref={InputFilePhotosDropZone}
                                    multiple
                                    onChange={handleFileSelect}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        )}

                        {props.content.photos.map((photo, index) => {
                            return (
                                <DashboardCategoryContentLine content={photo} structure='photos-in-projet' />
                            )
                        })}
                    </>
                    )

                    :
                    (props.structure === 'photos-in-projet' ?
                        (<div className={`dashboard-category-content ${props.structure} ${props.content.isNew ? 'is-new' : ''}`} id={`dashboard-${props.structure}-` + props.content.id} key={props.content.id}>

                            <div className='dashboard-category-image photo'>
                                {modifState ?
                                    <>
                                        <div className='image-container'>
                                            <img src={'/photographies/' + props.content.name_photo} alt={props.content.name_photo} />
                                        </div>

                                        <div className="inputs">
                                            <input type='file' ref={InputFilePhoto} />

                                        </div>
                                    </>

                                    :

                                    <div className='image-container'>
                                        <img src={'/photographies/' + props.content.name_photo} alt={props.content.name_photo} />
                                    </div>


                                }
                            </div>

                            {/* OLD */}
                            {/*<div className='dashboard-category-size'>
                                {modifState ?
                                    <>
                                        <div className='input-item'>
                                            <p>Importance: </p>
                                            <select ref={importanceSelectPhotos}>
                                                {selectOptions.importance.map((option, index) => {
                                                    let selected = (option === props.content.IMPORTANCE) ? true : false
                                                    return (
                                                        <option key={index} value={option} selected={selected} >{option}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div className='input-item'>
                                            <p>Size: </p>
                                            <select ref={sizeSelectPhotos}>
                                                {selectOptions['size_' + props.structure].map((option, index) => {
                                                    let selected = (option === props.content.SIZE) ? true : false
                                                    return (
                                                        <option key={index} value={option} selected={selected} >{option}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </>

                                    :
                                    (
                                        (props.content.IMPORTANCE !== 'none' && props.content.SIZE !== 'normal' ?
                                            <>
                                                <p className='importance'>{props.content.IMPORTANCE}</p>
                                                <p className='importance'>{props.content.SIZE}</p>
                                            </>
                                            :
                                            props.content.IMPORTANCE !== 'none' ?
                                                <p className='importance'>{props.content.IMPORTANCE}</p>
                                                :
                                                props.content.SIZE !== 'normal' ?
                                                    <p className='importance'>{props.content.SIZE}</p>
                                                    :
                                                    null
                                        ))

                                }
                            </div>*/}
                            <div className='dashboard-category-size'>
                                <ImportanceSizeSelector type='importance' content={props.content}/>
                                <ImportanceSizeSelector type='size' content={props.content}/>
                            </div>


                            <div className='dashboard-category-tags'>
                                {modifState ?
                                    <div ref={tagsContainer}>
                                        {allTags.map((tag, index) => {
                                            let selected = (props.content.tags.find(tagInArray => tagInArray.id === tag.id)) ? 'selected' : ''
                                            return (
                                                <div className={`dashboard-tag ${selected}`} ref={tagsDiv} onClick={(e) => handleTagClick(e)} key={index} data-tag-id={tag.id}>
                                                    <img src={'/assets/tags/' + tag.tag_image_name} alt={tag.tag_image_name} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    :
                                    // BEFORE
                                    // props.content.tags.map((tag, index) => {
                                    //     return (
                                    //         <div className='dashboard-tag selected' key={index}>
                                    //             <img src={'/assets/tags/' + tag.tag_image_name} alt={tag.tag_image_name} />
                                    //         </div>
                                    //     )
                                    // })
                                    <div ref={tagsContainer}>
                                        {allTags.map((tag, index) => {
                                            let selected = (props.content.tags.find(tagInArray => tagInArray.id === tag.id)) ? 'selected' : ''
                                            return (
                                                <div className={`dashboard-tag ${selected}`} ref={tagsDiv} onClick={(e) => handleTagClick(e)} key={index} data-tag-id={tag.id}>
                                                    <img src={'/assets/tags/' + tag.tag_image_name} alt={tag.tag_image_name} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>

                            <ModifDeleteButtons onModify={modifClick} onSave={save} onDelete={deleteItem} />

                        </div>)

                        :
                        (props.structure === 'documentaires' ?
                            <>
                                <div className={`dashboard-category-content ${props.structure} ${props.content.isNew ? 'is-new' : ''}`} id={`dashboard-${props.structure}-` + props.content.id} key={props.content.id}>

                                    <div className='dashboard-category-name'>
                                        {modifState ?
                                            <>
                                                <div className='input-item'>
                                                    <p>Titre: </p>
                                                    <input type='text' ref={titleFRInputDocu} placeholder={props.content.title_fr} />
                                                </div>
                                                <div className='input-item'>
                                                    <p>Titre [en]: </p>
                                                    <input type='text' ref={titleENInputDocu} placeholder={props.content.title_en} />
                                                </div>
                                            </>
                                            :
                                            <h3><span>Titre:</span> {props.content.title_fr}</h3>
                                        }
                                    </div>

                                    <div className='dashboard-category-name'>
                                        {modifState ?
                                            <>
                                                <div className='input-item'>
                                                    <p>Credits: </p>
                                                    <textarea ref={descriptionFRInputDocu} value={description_fr} onChange={(e) => setDescription_fr(e.target.value)} />
                                                </div>
                                                <div className='input-item'>
                                                    <p>Credits [en]: </p>
                                                    <textarea ref={descriptionENInputDocu} value={description_en} onChange={(e) => setDescription_en(e.target.value)} />
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                    </div>


                                    <div className='dashboard-category-name'>
                                        {modifState ?
                                            <>
                                                <div className='input-item'>
                                                    <p>Position: </p>
                                                    <input type='number' ref={positionFRInputDocu} placeholder={props.content.position} />
                                                </div>
                                                <div className='input-item'>
                                                    <p>Type: </p>
                                                    <select ref={typeSelectDocu}>
                                                        {typeSelectDocuOptions.map((option, index) => {
                                                            let selected = (option === props.content.image_or_video) ? true : false
                                                            return (
                                                                <option key={index} value={option} selected={selected} >{option}</option>)
                                                        })}

                                                    </select>

                                                </div>
                                            </>
                                            :
                                            <>
                                                <h3><span>Position:</span> {props.content.position}</h3>
                                                <h3><span>Type:</span> {props.content.image_or_video}</h3>
                                            </>
                                        }
                                    </div>



                                    <ModifDeleteButtons onModify={modifClick} onSave={save} onDelete={deleteItem} />
                                </div>



                                {modifState && props.content.image_or_video !== 'video' && (
                                    <div
                                        className='dropzone'
                                        onDrop={handleDrop}
                                        onDragOver={handleDragOver}
                                        onDragEnter={handleDragEnter}
                                        onDragLeave={handleDragLeave}
                                    >
                                        <p>Drag & drop files here, or click to select files</p>
                                        <input
                                            type='file'
                                            ref={InputFilePhotosDropZoneDocuImage}
                                            multiple
                                            onChange={handleFileSelect}
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                )}

                                {props.content.image_or_video === 'video' ? <DashboardCategoryContentLine content={props.content} structure={'docu-video'} />
                                    :
                                    props.content.images.length > 0 ?
                                        props.content.images.map((image, index) => {
                                            return (
                                                <DashboardCategoryContentLine content={image} structure={'docu-photo'} />
                                            )
                                        })
                                        :
                                        null

                                }
                            </>
                            :
                            (props.structure === 'docu-video' ?
                                <>
                                    <div className={`dashboard-category-content ${props.structure} ${props.content.isNew ? 'is-new' : ''}`} id={`dashboard-${props.structure}-` + props.content.id} key={props.content.id}>

                                        <div className='dashboard-category-video'>
                                            {modifState ?

                                                <div className="inputs">
                                                    <div className='input-item' >
                                                        <p>link: </p>
                                                        <select ref={selectExternalInternalDocuVideo} onChange={(e) => setInternalExternalDocuVideo(e.target.value)} >
                                                            {selectOptions.links.map((option, index) => {
                                                                let selected = (option === props.content.external_or_internal) ? true : false
                                                                return (
                                                                    <option key={index} value={option} selected={selected} >{option}</option>)
                                                            })}
                                                        </select>
                                                    </div>

                                                    {internalExternalDocuVideo === 'internal' ?

                                                        <input type='file' ref={linkInputFileDocuVideo} />
                                                        :
                                                        <input type='text' placeholder={props.content.video_link} ref={linkInputTextDocuVideo} />
                                                    }

                                                </div>

                                                :

                                                <div className='video-container'>
                                                    {props.content.external_or_internal === 'internal' ?
                                                        <ReactPlayer url={'/documentaires/' + props.content.video_link} playing={false} controls={true} light={true} width='100%' height='100%' playsinline />
                                                        :
                                                        <ReactPlayer url={props.content.video_link} playing={false} controls={true} light={true} width='100%' height='100%' playsinline />
                                                    }

                                                </div>


                                            }
                                        </div>

                                        <ModifDeleteButtons onModify={modifClick} onSave={save} />
                                    </div>
                                </>
                                :
                                (props.structure === 'docu-photo' ?
                                    (<div className={`dashboard-category-content ${props.structure} ${props.content.isNew ? 'is-new' : ''}`} id={`dashboard-${props.structure}-` + props.content.id} key={props.content.id}>

                                        <div className='dashboard-category-image photo'>
                                            {modifState ?
                                                <>
                                                    <div className='image-container'>
                                                        <img src={'/documentaires/' + props.content.name} alt={props.content.name} />
                                                    </div>

                                                    <div className="inputs">
                                                        <input type='file' ref={InputFilePhotoDocuImage} />

                                                    </div>
                                                </>

                                                :

                                                <div className='image-container'>
                                                    <img src={'/documentaires/' + props.content.name} alt={props.content.name} />
                                                </div>


                                            }
                                        </div>


                                        <div className='dashboard-category-name'>
                                            {modifState ?
                                                <>
                                                    <div className='input-item'>
                                                        <p>Position: </p>
                                                        <input type='number' ref={positionDocuImage} placeholder={props.content.position} />
                                                    </div>
                                                </>
                                                :
                                                props.content.position !== null ?
                                                    <h3><span>Position:</span> {props.content.position}</h3>
                                                    :
                                                    null
                                            }
                                        </div>







                                        <ModifDeleteButtons onModify={modifClick} onSave={save} onDelete={deleteItem} />

                                    </div>)
                                    :
                                    null

                                )
                            )
                        )
                    ))

            ))
    )
}

export default DashboardCategoryContentLine




