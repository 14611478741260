import React, { useEffect,useState } from 'react';
import axios from 'axios';
const config = require('../config.json');

export const ProjectListContext = React.createContext()

export function ProjectListProvider({ children }) {


    const [openedProjectCategorie, setOpenedProjectCategorie] = useState('close');
    const [projectListData, setProjectListData] = useState();
    const [categoriesListData, setCategoriesListData] = useState();

    function closeCategorieProject() {
        setOpenedProjectCategorie('close')
        // console.log("categorie closed: ")
    };

    function openCategorieProject(categorie) {
        setOpenedProjectCategorie(categorie)
        // console.log("categorie opened: ", categorie)
    };

    const fetchProjectsList = async () => {
        axios.get(config['apiUrl']+'api/projects')
        .then(response => {
            // console.log(response.data);
            setProjectListData(response.data);
        })
        .catch(error => {
            console.error('There was an error fetching the data!', error);
        });
    };

    const fetchCategoriesList = async () => {
        axios.get(config['apiUrl']+'api/categories')
        .then(response => {
            setCategoriesListData(response.data);
        })
        .catch(error => {
            console.error('There was an error fetching the data!', error);
        });
    }


    useEffect(() => {
        fetchProjectsList();
        fetchCategoriesList();
    }, []);


    const value = { openedProjectCategorie, closeCategorieProject, openCategorieProject, projectListData, categoriesListData };

    return (
        <ProjectListContext.Provider value={value}>
            {children}
        </ProjectListContext.Provider>
    );
}