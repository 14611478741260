import React, {useEffect} from 'react';
import '../css/mentions-legales.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import  changeCanonialLink  from '../utils/changeCanonialLink';


function RGPD() {

  const { t, i18n } = useTranslation();

    // set title and meta description
    useEffect(() => {
        const title = t('RGPD')+' - Louis Gaday';
        document.title = title;
    }, [t])


    const location = useLocation();
    useEffect(() => {
        changeCanonialLink(location.pathname);
    }, [location]);

  return (
    i18n.language === 'fr' ?
      <section className='gdpr-compliance'>
        <button className="back-button" onClick={() => window.location.href = '/about'}>Retour</button>
        <h2>Conformité RGPD</h2>
        <p>
          Nous nous engageons à respecter votre vie privée et à nous conformer au Règlement Général sur la Protection des Données (RGPD).
          Ce site web ne collecte ni ne traite aucune donnée personnelle. Les seules données stockées sont dans le local storage,
          utilisées uniquement pour se souvenir de vos interactions sur ce site, telles que vos clics et préférences.
        </p>
        <p>
          Si vous avez des questions ou des préoccupations concernant vos données, {"n'hésitez"} pas à nous contacter à : contact@remy-eroes.site.
        </p>
      </section>
      :

      <section className='gdpr-compliance'>
        <button className="back-button" onClick={() => window.location.href = '/about'}>Back</button>
        <h2>GDPR Compliance</h2>
        <p>
          We are committed to respecting your privacy and complying with the General Data Protection Regulation (GDPR).
          This website does not collect or process any personal data. The only data stored is in local storage,
          which is used solely to remember your interactions on this site, such as your clicks and preferences.
        </p>
        <p>
          If you have any concerns or questions regarding your data, please feel free to contact us: contact@remy-eroes.site.
        </p>
      </section>


  );
};

export default RGPD;